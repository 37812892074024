import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FaAccessibleIcon, FaKey, FaList, FaQrcode, FaThList, FaUser } from "react-icons/fa";
import { Input, Button, WarningCallout, Select as SimpleSelect, Radios, BackLink, Card, Details, ErrorSummary, Fieldset, Label, ActionLink, Checkboxes, SummaryList } from "nhsuk-react-components";
import { getPatientDetails, patientUpdateConsentIds, updatePatientConsentIds } from "../../actions/patientActions";
import { listOrganisations } from "../../actions/organisationActions";
import { listEcgs } from "../../actions/ecgActions";
import { listSymptoms } from "../../actions/symptomActions";
import { listAetiologies } from "../../actions/aetiologyActions";
import { listFollowupCentres } from "../../actions/followupCentreActions";
import { listImplantCentres } from "../../actions/implantCentreActions";
import { listImplants } from "../../actions/implantActions";
import { listConsents } from "../../actions/consentActions";
import { getShortUrlDetailsbyUserId} from "../../actions/shortUrlActions";
import Select from 'react-select';
import { debounce } from 'lodash';
// import modal from components
import Modal from "../../components/modal/Modal.component";
import QRCode from "react-qr-code";
import Callout from "../../components/callout/Callout.component";
import ImplantInstanceListReadOnly from "../../components/implantInstanceList/ImplantInstanceListReadOnly.component";
import { PATIENT_UPDATE_RESET } from "../../constants/patientConstants";
// import date functions
import { convertToComponentDate } from "../../utils/functions";

export default function PatientProfileScreen({ history }) {

    const dispatch = useDispatch();

    useEffect(() => {
        document.title = "My Heart Passport"; 
    }, []);


    // reset states
    useEffect(() => {
        dispatch({ type: PATIENT_UPDATE_RESET });
    }, [dispatch]);

    const [patientId, setPatientId] = useState();
    const [userId, setUserId] = useState();

    // Modal
    const [consentModalShow, setConsentModalShow] = useState(false);
    const [genericModalShow, setGenericModalShow] = useState(false);

    const openConsentModal = () => {
        setConsentModalShow(true);
    };

    const closeModal = () => {
        setConsentModalShow(false);
    };

    const openGenericModal = () => {
        setGenericModalShow(true);
    };

    const closeGenericModal = () => {
        setGenericModalShow(false);
    };

    const [consentModalContent, setConsentModalContent] = useState();
    const [genericModalContent, setGenericModalContent] = useState();

    const [targetDevice, setTargetDevice] = useState("android");

    const [newPatient, setNewPatient] = useState({});
    // get day month year
    const [dateofbirth, setdateofbirth] = useState(convertToComponentDate(Date.now()));
    const [generatorImplantDate, setgeneratorImplantDate] = useState(convertToComponentDate(Date.now()));

    // Consent properties to ignore
    // the following is a list of combined properties
    const consentPropertiesToIgnore = ["id", "patientConsents"];

    const genericPropertiesToIgnore = ["id", "patientEcgs", "patientSymptoms", "patientAetiologies", "patientImplants", "patientImplantCentres", "patientFollowUpCentres", "patientConsents", "patient"];

    // pass url
    const [passUrl, setPassUrl] = useState("");

    // selected ecgs
    const [selectedEcgs, setSelectedEcgs] = useState([]);
    const [selectedSymptoms, setSelectedSymptoms] = useState([]);
    const [selectedAetiologies, setSelectedAetiologies] = useState([]);
    const [selectedImplants, setSelectedImplants] = useState([]);
    const [selectedImplantCentres, setSelectedImplantCentres] = useState([]);
    const [selectedFollowupCentres, setSelectedFollowupCentres] = useState([]);
    const [selectedConsents, setSelectedConsents] = useState([]);
    // Pass data
    const [passData, setPassData] = useState({});

    // generic working state
    const [working, setWorking] = useState(false);

    // get userinfo from local storage
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    // load patient details
    const patientDetails = useSelector((state) => state.patientDetails);
    const { loading, error, patient } = patientDetails;

    // update patient consentIds
    const patientUpdateConsentIds = useSelector((state) => state.patientUpdateConsentIds);
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate, patient: patientUpdated } = patientUpdateConsentIds;

    // load organisations
    const organisationList = useSelector((state) => state.organisationList);
    const { loading: loadingOrganisation, error: errorOrganisation, organisations } = organisationList;

    // load ecgs
    const ecgList = useSelector((state) => state.ecgList);
    const { loading: loadingEcg, error: errorEcg, ecgs } = ecgList;

    // load implants
    const implantList = useSelector((state) => state.implantList);
    const { loading: loadingImplant, error: errorImplant, implants } = implantList;

    // load symptoms
    const symptomList = useSelector((state) => state.symptomList);
    const { loading: loadingSymptom, error: errorSymptom, symptoms } = symptomList;

    // load aetiologies
    const aetiologyList = useSelector((state) => state.aetiologyList);
    const { loading: loadingAetiology, error: errorAetiology, aetiologies } = aetiologyList;

    // load followup centres
    const followupCentreList = useSelector((state) => state.followupCentreList);
    const { loading: loadingFollowupCentre, error: errorFollowupCentre, followupCentres } = followupCentreList;

    // load implant centres
    const implantCentreList = useSelector((state) => state.implantCentreList);
    const { loading: loadingImplantCentre, error: errorImplantCentre, implantCentres } = implantCentreList;

    // load consents
    const consentList = useSelector((state) => state.consentList);
    const { loading: loadingConsent, error: errorConsent, consents } = consentList;

    // create shortUrl
    const shortUrlCreate = useSelector((state) => state.shortUrlCreate);
    const { loading: loadingShortUrlCreate, error: errorShortUrlCreate, success: successShortUrlCreate, shortUrl } = shortUrlCreate;

    // short url details by userId
    const shortUrlDetailsByUserId = useSelector((state) => state.shortUrlDetailsByUserId);
    const { loading: loadingShortUrlDetailsByUserId, error: errorShortUrlDetailsByUserId, success: successShortUrlDetailsByUserId, shortUrl: shortUrlByUserId } = shortUrlDetailsByUserId;


    // listen to userInfo
    useEffect(() => {
        setPatientId(userInfo.patientId);
        setUserId(userInfo.userId);
    }, [userInfo, history]);


    // get patient details
    useEffect(() => {
        // reset pass url
        dispatch(getPatientDetails(patientId));
        dispatch(listOrganisations());
        dispatch(listEcgs());
        dispatch(listSymptoms());
        dispatch(listAetiologies());
        dispatch(listFollowupCentres());
        dispatch(listImplantCentres());
        dispatch(listImplants());
        dispatch(getShortUrlDetailsbyUserId(patientId));
        dispatch(listConsents());
    }, [dispatch, patientId]);


    // listen to patient details changes
    useEffect(() => {
        if (patient && ecgs && symptoms && aetiologies && implants && implantCentres && followupCentres
            && patient.ecgIds && patient.symptomIds && patient.aetiologyIds && patient.implantIds
            && patient.implantCentreIds && patient.followUpCentreIds) {
            // update selected ecgs
            setSelectedEcgs(ecgs?.map(ecg => ({ value: ecg.id, label: ecg.name })).filter(ecg => patient.ecgIds?.includes(ecg.value)));
            // update selected symptoms
            setSelectedSymptoms(symptoms?.map(symptom => ({ value: symptom.id, label: symptom.name })).filter(symptom => patient.symptomIds?.includes(symptom.value)));
            // update selected aetiologies
            setSelectedAetiologies(aetiologies?.map(aetiology => ({ value: aetiology.id, label: aetiology.name })).filter(aetiology => patient.aetiologyIds?.includes(aetiology.value)));
            // update selected implants
            setSelectedImplants(implants?.map(implant => ({ value: implant.id, label: implant.barcode })).filter(implant => patient.implantIds?.includes(implant.value)));
            // update selected implant centres
            setSelectedImplantCentres(implantCentres?.map(implantCentre => ({ value: implantCentre.id, label: implantCentre.name })).filter(implantCentre => patient.implantCentreIds?.includes(implantCentre.value)));
            // update selected followup centres
            setSelectedFollowupCentres(followupCentres?.map(followupCentre => ({ value: followupCentre.id, label: followupCentre.name })).filter(followupCentre => patient.followUpCentreIds?.includes(followupCentre.value)));
            // set selected consents
            setSelectedConsents(consents?.map(consent => ({ value: consent.id, label: consent.consentType })).filter(consent => patient.consentIds?.includes(consent.value)));
            // update date of birth
            setdateofbirth(convertToComponentDate(patient?.dateOfBirth));
            // update generator implant date
            setgeneratorImplantDate(convertToComponentDate(patient?.generatorImplantDate));
            // update new patient
            setNewPatient(patient);

        }
    }, [patient, ecgs, symptoms, aetiologies, implants, implantCentres, followupCentres, consents]);

    // listen to shortUrl
    useEffect(() => {

        if (shortUrl) {
            setPassUrl(`/api/v1/ShortUrl/code/${shortUrl.data.shortCode}/${targetDevice}`);
        } else {
            setPassUrl("");
        }
    }, [shortUrl, targetDevice]);

    // listen to shortUrlByUserId
    useEffect(() => {
        if (shortUrlByUserId && shortUrlByUserId.shortCode) {
            setPassUrl(`/api/v1/ShortUrl/code/${shortUrlByUserId.shortCode}/${targetDevice}`);
        } else {
            setPassUrl("");
        }
    }, [shortUrlByUserId, targetDevice]);


    // listen to selected ecgs
    useEffect(() => {
        if (selectedEcgs) {
            setNewPatient({ ...newPatient, ecgIds: selectedEcgs.map(ecg => ecg.value) });
        }
    }, [selectedEcgs]);

    // listen to selected symptoms
    useEffect(() => {
        if (selectedSymptoms) {
            setNewPatient({ ...newPatient, symptomIds: selectedSymptoms.map(symptom => symptom.value) });
        }
    }, [selectedSymptoms]);

    // listen to selected aetiologies
    useEffect(() => {
        if (selectedAetiologies) {
            setNewPatient({ ...newPatient, aetiologyIds: selectedAetiologies.map(aetiology => aetiology.value) });
        }
    }, [selectedAetiologies]);

    // listen to selected implants
    useEffect(() => {
        if (selectedImplants) {
            setNewPatient({ ...newPatient, implantIds: selectedImplants.map(implant => implant.value) });
        }
    }, [selectedImplants]);

    // listen to selected implant centres
    useEffect(() => {
        if (selectedImplantCentres) {
            setNewPatient({ ...newPatient, implantCentreIds: selectedImplantCentres.map(implantCentre => implantCentre.value) });
        }
    }, [selectedImplantCentres]);

    // listen to selected followup centres
    useEffect(() => {
        if (selectedFollowupCentres) {
            setNewPatient({ ...newPatient, followUpCentreIds: selectedFollowupCentres.map(followupCentre => followupCentre.value) });
        }
    }, [selectedFollowupCentres]);

    // listen to selected consents
    useEffect(() => {
        if (selectedConsents) {
            setNewPatient({ ...newPatient, consentIds: selectedConsents.map(consent => consent.value) });
        }
    }, [selectedConsents]);


    // handle click on the checkboxes 
    // get the consent JSON and display it in a modal
    const handleConsentClick = (consentId) => {
        const consent = consents.find(consent => consent.id === consentId);
        setConsentModalContent(consent);
        openConsentModal();
    };

    // handle display entity details in modal
    const handleEntityClick = (entity) => {
        if (!entity) return;
        setGenericModalContent(entity);
        openGenericModal();
    };


    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>
                    {userInfo && !userInfo.roles.includes("Patient") ? (
                        <WarningCallout label="Warning callout">
                            <WarningCallout.Label>Patient information</WarningCallout.Label>
                            <p>Hi {userInfo.username},</p>
                            <p>Please note that access to this page requires "Patient" privilege. If you are a patient, kindly contact an administrator to have your account set up accordingly.</p>
                        </WarningCallout>
                    ) : (

                        <div className="nhsuk-grid-row">

                            <div className="welcome-title d-flex justify-content-between align-items-center">

                                <h3>My Profile</h3>
                                <BackLink
                                    asElement="a"
                                    href="#"
                                    onClick={() => history.goBack()}
                                >
                                    Return back
                                </BackLink>

                            </div>
                            <div className="nhsuk-grid-column-one-half">
                                <div className="welcome-box">

                                    <div className="welcome-content">
                                        <div className="welcome-text">

                                            {newPatient?.identityUserId && (
                                                <Card style={{ background: "rgb(255, 255, 204)", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}>
                                                    <Card.Content>
                                                        <Card.Heading className="nhsuk-heading-m">
                                                            <div className="icon-container ">
                                                                <FaThList size={30} />
                                                            </div>
                                                            Manage your consents
                                                        </Card.Heading>
                                                        <Card.Description>
                                                            Decide on how your data is used, and who can access it.
                                                        </Card.Description>

                                                        <Checkboxes>
                                                            {
                                                                consents?.map(consent => (
                                                                    <Checkboxes.Box
                                                                        key={consent.id}
                                                                        conditional={`Scope: ${consent.consentScope}`}
                                                                        checked={selectedConsents?.map(selectedConsent => selectedConsent.value).includes(consent.id)}
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                setSelectedConsents([...selectedConsents, { value: consent.id, label: consent.consentType }]);
                                                                            } else {
                                                                                setSelectedConsents(selectedConsents.filter(selectedConsent => selectedConsent.value !== consent.id));
                                                                            }
                                                                        }}
                                                                        onClick={() => {
                                                                            if (!selectedConsents?.map(selectedConsent => selectedConsent.value).includes(consent.id)) {
                                                                                handleConsentClick(consent.id);
                                                                            }
                                                                        }}
                                                                    >
                                                                        {consent.consentType}
                                                                    </Checkboxes.Box>
                                                                ))
                                                            }

                                                        </Checkboxes>

                                                        <Button
                                                            disabled={loadingUpdate}
                                                            type="submit"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                dispatch(updatePatientConsentIds(patientId, newPatient));
                                                            }}
                                                        >   Update
                                                        </Button>

                                                        {successUpdate && (
                                                            <Callout
                                                                label="Success!"
                                                                mode="success"
                                                                header="Consents updated"
                                                                message="Consents updated successfully"
                                                            />

                                                        )}

                                                    </Card.Content>



                                                </Card>
                                            )}
                                            <Card>
                                                {newPatient?.identityUserId ? (

                                                    <Card.Content>
                                                        <Card.Heading className="nhsuk-heading-m">
                                                            <div className="icon-container ">
                                                                <FaUser size={30} />
                                                            </div>
                                                            {newPatient?.forename} {newPatient?.surname}
                                                        </Card.Heading>

                                                        <h4>My Implants</h4>

                                                        <ImplantInstanceListReadOnly
                                                            patientNHSNumber={newPatient?.nhsNumber}
                                                        />


                                                        <SummaryList>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key>
                                                                    Identity User
                                                                </SummaryList.Key>
                                                                <SummaryList.Value>
                                                                    {newPatient?.identityUserId}
                                                                </SummaryList.Value>
                                                            </SummaryList.Row>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key>
                                                                    NHS Number
                                                                </SummaryList.Key>
                                                                <SummaryList.Value>
                                                                    {newPatient?.nhsNumber}
                                                                </SummaryList.Value>
                                                            </SummaryList.Row>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key>
                                                                    Date of Birth
                                                                </SummaryList.Key>
                                                                <SummaryList.Value>
                                                                    {dateofbirth?.day}/{dateofbirth?.month}/{dateofbirth?.year}
                                                                </SummaryList.Value>
                                                            </SummaryList.Row>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key>
                                                                    Email
                                                                </SummaryList.Key>
                                                                <SummaryList.Value>
                                                                    {newPatient?.email}
                                                                </SummaryList.Value>
                                                            </SummaryList.Row>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key>
                                                                    Phone Number
                                                                </SummaryList.Key>
                                                                <SummaryList.Value>
                                                                    {newPatient?.phoneNumber}
                                                                </SummaryList.Value>
                                                            </SummaryList.Row>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key>
                                                                    Abandoned Hardware?
                                                                </SummaryList.Key>
                                                                <SummaryList.Value>
                                                                    {newPatient?.abondonedHardware ? "Yes" : "No"}
                                                                </SummaryList.Value>
                                                            </SummaryList.Row>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key>
                                                                    MR Conditional?
                                                                </SummaryList.Key>
                                                                <SummaryList.Value>
                                                                    {newPatient?.mrConditional ? "Yes" : "No"}
                                                                </SummaryList.Value>
                                                            </SummaryList.Row>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key>
                                                                    Implant Centres
                                                                </SummaryList.Key>
                                                                <SummaryList.Value>
                                                                    <ul>
                                                                        {
                                                                            newPatient?.implantCentreIds?.map(implantCentreId => (
                                                                                <li>

                                                                                    <Link
                                                                                        to="#"
                                                                                        onClick={() => {
                                                                                            const selectedImplantCentre = implantCentres?.find(implantCentre => implantCentre.id === implantCentreId);
                                                                                            handleEntityClick(selectedImplantCentre);
                                                                                        }}
                                                                                    >
                                                                                        {implantCentres?.find(implantCentre => implantCentre.id === implantCentreId)?.name}
                                                                                    </Link>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>

                                                                </SummaryList.Value>
                                                            </SummaryList.Row>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key>
                                                                    Followup Centres
                                                                </SummaryList.Key>
                                                                <SummaryList.Value>
                                                                    <ul>
                                                                        {
                                                                            newPatient?.followUpCentreIds?.map(followUpCentreId => (
                                                                                <li>

                                                                                    <Link
                                                                                        to="#"
                                                                                        onClick={() => {
                                                                                            const selectedFollowupCentre = followupCentres?.find(followupCentre => followupCentre.id === followUpCentreId);
                                                                                            handleEntityClick(selectedFollowupCentre);
                                                                                        }}
                                                                                    >
                                                                                        {followupCentres?.find(followupCentre => followupCentre.id === followUpCentreId)?.name}
                                                                                    </Link>

                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>

                                                                </SummaryList.Value>
                                                            </SummaryList.Row>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key>
                                                                    Symptoms
                                                                </SummaryList.Key>
                                                                <SummaryList.Value>
                                                                    <ul>
                                                                        {
                                                                            newPatient?.symptomIds?.map(symptomId => (
                                                                                <li>

                                                                                    <Link
                                                                                        to="#"
                                                                                        onClick={() => {
                                                                                            const selectedSymptom = symptoms?.find(symptom => symptom.id === symptomId);
                                                                                            handleEntityClick(selectedSymptom);
                                                                                        }}
                                                                                    >
                                                                                        {symptoms?.find(symptom => symptom.id === symptomId)?.name}
                                                                                    </Link>

                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>

                                                                </SummaryList.Value>
                                                            </SummaryList.Row>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key>
                                                                    Aetiologies
                                                                </SummaryList.Key>
                                                                <SummaryList.Value>
                                                                    <ul>
                                                                        {
                                                                            newPatient?.aetiologyIds?.map(aetiologyId => (
                                                                                <li>

                                                                                    <Link
                                                                                        to="#"
                                                                                        onClick={() => {
                                                                                            const selectedAetiology = aetiologies?.find(aetiology => aetiology.id === aetiologyId);
                                                                                            handleEntityClick(selectedAetiology);
                                                                                        }}
                                                                                    >
                                                                                        {aetiologies?.find(aetiology => aetiology.id === aetiologyId)?.name}
                                                                                    </Link>

                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>

                                                                </SummaryList.Value>
                                                            </SummaryList.Row>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key>
                                                                    ECGs
                                                                </SummaryList.Key>
                                                                <SummaryList.Value>
                                                                    <ul>
                                                                        {
                                                                            newPatient?.ecgIds?.map(ecgId => (
                                                                                <li>

                                                                                    <Link
                                                                                        to="#"
                                                                                        onClick={() => {
                                                                                            const selectedEcg = ecgs?.find(ecg => ecg.id === ecgId);
                                                                                            handleEntityClick(selectedEcg);
                                                                                        }}
                                                                                    >
                                                                                        {ecgs?.find(ecg => ecg.id === ecgId)?.name}
                                                                                    </Link>


                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>

                                                                </SummaryList.Value>
                                                            </SummaryList.Row>

                                                        </SummaryList>

                                                    </Card.Content>
                                                ) : (
                                                    (loading && !patient) ?
                                                        <Card.Content>
                                                            <Card.Heading className="nhsuk-heading-m">
                                                                Fetching patient details...
                                                            </Card.Heading>
                                                        </Card.Content>
                                                        : <Card.Content>
                                                            <Card.Heading className="nhsuk-heading-m">
                                                                <div className="icon-container ">
                                                                    <FaUser size={30} />
                                                                </div>
                                                                {newPatient?.forename} {newPatient?.surname}
                                                            </Card.Heading>
                                                            <Card.Description>
                                                                Please contact an administrator to have your account set up accordingly.
                                                            </Card.Description>
                                                        </Card.Content>
                                                )}


                                            </Card>

                                            <Modal show={consentModalShow} handleClose={closeModal}>
                                                <h2>{consentModalContent?.consentType}</h2>
                                                {consentModalContent && (
                                                    <SummaryList>
                                                        {
                                                            Object.entries(consentModalContent).map(([key, value]) => (
                                                                !consentPropertiesToIgnore.includes(key) &&
                                                                <SummaryList.Row>
                                                                    <SummaryList.Key>
                                                                        {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                                                                    </SummaryList.Key>
                                                                    <SummaryList.Value>
                                                                        {value ? value.toString() : "-"}
                                                                    </SummaryList.Value>
                                                                </SummaryList.Row>
                                                            ))
                                                        }

                                                    </SummaryList>

                                                )}
                                                <Button
                                                    type="submit"
                                                    disabled={loadingUpdate}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        dispatch(updatePatientConsentIds(patientId, newPatient));
                                                        closeModal();
                                                    }
                                                    }
                                                >   I Agree to the above
                                                </Button>

                                            </Modal>

                                            <Modal show={genericModalShow} handleClose={closeGenericModal}>
                                                {genericModalContent && (
                                                    <SummaryList>
                                                        {
                                                            Object.entries(genericModalContent).map(([key, value]) => (
                                                                !genericPropertiesToIgnore.includes(key) &&
                                                                <SummaryList.Row>
                                                                    <SummaryList.Key>
                                                                        {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                                                                    </SummaryList.Key>
                                                                    <SummaryList.Value>
                                                                        {value ? value.toString() : "-"}
                                                                    </SummaryList.Value>
                                                                </SummaryList.Row>
                                                            ))
                                                        }

                                                    </SummaryList>

                                                )}
                                                <Button
                                                    type="submit"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        closeGenericModal();
                                                    }
                                                    }
                                                >   Close
                                                </Button>

                                            </Modal>



                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="nhsuk-grid-column-one-third" style={{ display: 'flex', justifyContent: 'center' }}>

                                <Card style={{ padding: "10px" }}>

                                    <Card.Description>
                                        <Fieldset>
                                            <Radios name="device" inline id="inline-device" hint="Select the target device.">
                                                <Radios.Radio value="android"
                                                    default
                                                    checked={targetDevice === "android"}
                                                    onChange={(e) => {
                                                        setTargetDevice(e.target.value);
                                                    }
                                                    }
                                                >
                                                    Android
                                                </Radios.Radio>
                                                <Radios.Radio value="ios"
                                                    onChange={(e) => {
                                                        setTargetDevice(e.target.value);
                                                    }
                                                    }
                                                >

                                                    iOS</Radios.Radio>

                                            </Radios>

                                        </Fieldset>
                                        {passUrl?.length > 0 ? (

                                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                <div>
                                                    <div className="nhsuk-u-padding-top-3"></div>

                                                    <h2 style={{ textAlign: 'center' }}>Scan to Download Pass</h2>

                                                    <QRCode

                                                        size={256}
                                                        style={{ height: "auto", maxWidth: "100%", width: "100%", cursor: "hand" }}
                                                        value={passUrl ? (passUrl.includes(window.location.origin) ? passUrl : window.location.origin + passUrl) : ""}
                                                        viewBox={`0 0 256 256`}
                                                        onClick={
                                                            () => {
                                                                // navigate to passUrl new tab
                                                                window.open(passUrl, '_blank');
                                                            }
                                                        }
                                                    />
                                                </div>



                                            </div>
                                        ) : (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div>
                                                    <p style={{ textAlign: 'center' }}><FaQrcode size={200} /></p>
                                                    <Callout
                                                        label="Warning"
                                                        mode="warning"

                                                        header="No pass generated"
                                                        message="Your organisation will generate a pass for you shortly. Please check back later."
                                                    />





                                                </div>
                                            </div>
                                        )}


                                    </Card.Description>

                                </Card>
                            </div>

                        </div>

                    )}
                </Container>
            </div>
        </React.Fragment>
    );
}




