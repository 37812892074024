import axios from 'axios';

import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,

    USER_LOGOUT,

    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,

    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_DETAILS_RESET,

    IDENTITY_USER_DETAILS_REQUEST,
    IDENTITY_USER_DETAILS_SUCCESS,
    IDENTITY_USER_DETAILS_FAIL,
    IDENTITY_USER_DETAILS_RESET,

    IDENTITY_USER_UPDATE_REQUEST,
    IDENTITY_USER_UPDATE_SUCCESS,
    IDENTITY_USER_UPDATE_FAIL,
    IDENTITY_USER_UPDATE_RESET,

    IDENTITY_USER_CREATE_FAIL,
    IDENTITY_USER_CREATE_REQUEST,
    IDENTITY_USER_CREATE_RESET,
    IDENTITY_USER_CREATE_SUCCESS,


    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_RESET,

    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,

    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL,

    AUTHENTICATED_REQUEST,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,

    UPDATE_USER_PASSWORD_FAIL,
    UPDATE_USER_PASSWORD_REQUEST,
    UPDATE_USER_PASSWORD_SUCCESS,

    SEND_VERIFICATION_CODE_FAIL,
    SEND_VERIFICATION_CODE_REQUEST,
    SEND_VERIFICATION_CODE_SUCCESS,

    VERIFY_CODE_FAIL,
    VERIFY_CODE_REQUEST,
    VERIFY_CODE_SUCCESS,

} from '../constants/userConstants'

export const login = (email, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const { data } = await axios.post(
            '/api/v1/auth/signin/',
            { 'email': email, 'password': password },
            config
        )

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        console.log(data);

        localStorage.setItem('userInfo', JSON.stringify(data));

        // set 2fa code request to false
        localStorage.setItem('2fauthenticated', JSON.stringify(false));

        dispatch(getMeDetails())

    } catch (error) {

        // catch 400 response
        let errorMessage = error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message
        // catch 401 error

        dispatch({
            type: USER_LOGIN_FAIL,
            payload: errorMessage,
        })
    }
}


export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo')
    dispatch({ type: USER_LOGOUT })
    dispatch({ type: USER_DETAILS_RESET })
    dispatch({ type: USER_LIST_RESET })
}


export const register = (email, password, organisationId) => async (dispatch) => {
    try {
        dispatch({
            type: USER_REGISTER_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        
        const { data } = await axios.post(
            '/api/v1/auth/signup/',
            {
                'email': email,
                'password': password,
                'organisationId': organisationId,
            },
            config
        );

        dispatch(
            {
                type: USER_REGISTER_SUCCESS,
                payload: data
            });


    } catch (error) {

        dispatch({
            type: USER_REGISTER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })

    }
}

export const createIdentityUser = (identityUser) => async (dispatch) => {
    try {
        dispatch({
            type: IDENTITY_USER_CREATE_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        
        const { data } = await axios.post(
            '/api/v1/auth/signup/',
            identityUser,
            config
        );

        dispatch(
            {
                type: IDENTITY_USER_CREATE_SUCCESS,
                payload: data
            });



    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }


        dispatch({
            type: IDENTITY_USER_CREATE_FAIL,
            payload: errorMessages,
        })

    }
}


export const getIdentityUserDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: IDENTITY_USER_DETAILS_REQUEST
        })

        const {
            userLogin: { userInfo }, 
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/v1/user/${id}`, config);

        dispatch({
            type: IDENTITY_USER_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: IDENTITY_USER_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const getMeDetails = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_DETAILS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const data = await axios.get(
            '/api/v1/auth/me/',
            config
        )


        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: USER_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

// update identityUser details, the PUT request is sent to /api/v1/user/id
// and the body contains email, username, roles
export const updateIdentityUser = (id, changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: IDENTITY_USER_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },

        }

        const data = await axios.put(
            `/api/v1/user/${id}`,
            changes,
            config
        )

        dispatch({
            type: IDENTITY_USER_UPDATE_SUCCESS,
            payload: data
        })

        // no need to update data in the storage since we are not gonna update email

    } catch (error) {
        dispatch({
            type: IDENTITY_USER_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

// change password
export const resetPassword = (id, newPassword) => async (dispatch, getState) => {
    try {
        dispatch({
            type: UPDATE_USER_PASSWORD_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
        
        }

        const data = await axios.put(
            `/api/v1/user/${id}/updatepassword`,
            {
                "NewPassword": newPassword
            },
            config
        )

        dispatch({
            type: UPDATE_USER_PASSWORD_SUCCESS,
            payload: data
        })

        // no need to update data in the storage since we are not gonna update email

    } catch (error) {
        dispatch({
            type: UPDATE_USER_PASSWORD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}





// basic case : you can only update username, email
export const updateUser = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },

        }

        const data = await axios.patch(
            `/api/v1/user/update`,
            changes,
            config
        )

        dispatch({
            type: USER_UPDATE_SUCCESS,
            payload: data
        })

        // no need to update data in the storage since we are not gonna update email

    } catch (error) {
        dispatch({
            type: USER_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listUsers = (filter) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_LIST_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};

        // get the filter from the state
        if (filter) {
            params = filter;
        };

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            params
        }

        const { data } = await axios.get(
            `/api/v1/user/all`,
            config
        )

        dispatch({
            type: USER_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: USER_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later users",
        })
    }
}


export const deleteUser = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/v1/user/${id}`,
            config
        )

        dispatch({
            type: USER_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: USER_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const checkAuthenticated = () => async (dispatch, getState) => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (userInfo) {
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        try {

            const res = await axios.get(`/api/v1/auth/me/`, config)

            if (res.success != false) {
                dispatch({
                    type: AUTHENTICATED_SUCCESS
                });
            } else {
                dispatch({
                    type: AUTHENTICATED_FAIL
                });

            }
        } catch (error) {
            dispatch({
                type: AUTHENTICATED_FAIL
            });
        }

    } else {
        dispatch({
            type: AUTHENTICATED_FAIL
        });
    }

};
