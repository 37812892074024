import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Table, ActionLink, Label, Tag, Input, Fieldset, Radios, BackLink, Card, Details, Button, DateInput, InsetText, Textarea, ErrorSummary } from 'nhsuk-react-components';
import { listOrganisations, createOrganisation, updateOrganisation, deleteOrganisation, getOrganisationDetails } from '../../actions/organisationActions';
// constants 
import { ORGANISATION_CREATE_RESET, ORGANISATION_UPDATE_RESET } from '../../constants/organisationConstants';
import Pagination from '../../components/pagination/Pagination.component';
import AppSpinner from '../../components/spinner/AppSpinner.component';

import Callout from '../../components/callout/Callout.component';

// debouncer 
import { debounce } from 'lodash';

export default function OrganisationCRUDScreen({ history }) {

    // use react hook Dispatch to dispatch actions
    const dispatch = useDispatch();

    // reset the state of the organisationCreate and organisationUpdate
    useEffect(() => {
        dispatch({ type: ORGANISATION_CREATE_RESET });
        dispatch({ type: ORGANISATION_UPDATE_RESET });
    }, [dispatch]);

    // set initial filter to empty string, set default value 
    // {{ _.docker_api_url }}/api/v1/organisation/all?page=1&limit=2&filter=Email&query=gmail
    const [organisationListFilter, setorganisationListFilter] = useState({ query: '', page: 1, filter: 'Name' });

    // get organisation list from redux store
    const organisationList = useSelector((state) => state.organisationList);
    const { loading, error, organisations, currentPage, totalPages } = organisationList;

    // get organisation create from redux store
    const organisationCreate = useSelector((state) => state.organisationCreate);
    const { success: successCreate, error: organisationCreateErrors } = organisationCreate;

    // get organisation update from redux store
    const organisationUpdate = useSelector((state) => state.organisationUpdate);
    const { success: successUpdate, loading: organisationUpdateProcessing } = organisationUpdate;

    // get organisation delete from redux store
    const organisationDelete = useSelector((state) => state.organisationDelete);
    const { success: successDelete } = organisationDelete;

    // get organisation details from redux store
    const organisationDetails = useSelector((state) => state.organisationDetails);
    const { organisation: organisationDet, loading: organisationDetailsLoading } = organisationDetails;

    // newOrganisation state
    const [newOrganisation, setNewOrganisation] = useState({});
    const [updatedOrganisation, setUpdatedOrganisation] = useState({});
    const [editMode, setEditMode] = useState(false);


    // listen to filter changes
    useEffect(() => {
        dispatch(listOrganisations(organisationListFilter));
    }, [dispatch, organisationListFilter]);

    // handle page change
    const handlePageChange = (newPage) => {
        setorganisationListFilter({ ...organisationListFilter, page: newPage });
    };

    // handle search text field change
    const handleSearchChange = (e) => {
        setorganisationListFilter({ ...organisationListFilter, query: e.target.value });
    };

    // handle filter value change
    const handleFilterChange = (e) => {
        const selectedFilter = e.target.value;
        setorganisationListFilter({ ...organisationListFilter, filter: selectedFilter });
    };

    // get all organisations
    useEffect(() => {
        dispatch(listOrganisations({}));
    }, [dispatch]);

    // listen to organisationDet 
    useEffect(() => {
        if (organisationDet) {
            // use spread operator to copy the organisationDet object
            setUpdatedOrganisation({ ...organisationDet });
        }
    }, [organisationDet]);

    // listen to editMode
    useEffect(() => {
        if (editMode === false) {
            // empty the newOrganisation state
            setNewOrganisation({
                name: '',
                email: '',
                telephone: '',
                address: '',
                postalCode: '',
                city: '',
                state: '',
                country: '',
                website: '',
                description: '',
                fhirServerUrl: '',
                identifier: '',
            });
        }
    }, [editMode]);

    // handle delete
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this organisation?')) {
            dispatch(deleteOrganisation(id)).then(() => {
                dispatch(listOrganisations({}));
            }
            );
        }
    };

    // handle edit
    const handleEditOrganisation = (id) => {
        
        dispatch(getOrganisationDetails(id));
        setEditMode(true);
    };

    // handle create
    const handleCreateOrganisation = () => {
        dispatch(createOrganisation(newOrganisation)).then(() => {
            setUpdatedOrganisation(newOrganisation);
            setEditMode(true);
            dispatch(listOrganisations({}));
        }
        );
    };

    // handle update
    const handleUpdateOrganisation = () => {
        dispatch(updateOrganisation(updatedOrganisation.id, updatedOrganisation)).then(() => {

            setUpdatedOrganisation(updatedOrganisation);
            setEditMode(true);
            dispatch(getOrganisationDetails(updatedOrganisation.id));
        }
        );
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <div className="welcome-box">
                                <div className="welcome-title d-flex justify-content-between align-items-center">
                                    <h2>Organisation Management</h2>
                                </div>
                                <BackLink
                                    asElement="a"
                                    href="#"
                                    onClick={() => history.goBack()}
                                >
                                    Return back
                                </BackLink>
                                <InsetText>
                                    <p>
                                        This section allows you to manage and configure the organisations. You can create, update and delete organisations in one place!
                                    </p>
                                </InsetText>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">

                            {organisationCreateErrors ?

                                <ErrorSummary
                                    aria-labelledby="error-summary-title"
                                    role="alert"
                                    tabIndex="-1"
                                >
                                    <ErrorSummary.Title id="error-summary-title">
                                        There is a problem
                                    </ErrorSummary.Title>
                                    <ErrorSummary.Body>
                                        <p>Check the following:</p>
                                        {organisationCreateErrors ?
                                            <ul className="nhsuk-list nhsuk-list--bullet">
                                                {organisationCreateErrors.map((error) => (
                                                    <li key={error}>{error}</li>
                                                ))}
                                            </ul>
                                            : null

                                        }

                                    </ErrorSummary.Body>
                                </ErrorSummary>
                                : null}
                        </Col>
                    </Row>


                    <div className="nhsuk-grid-column-one-half">
                        <Card>
                            <Card.Content>
                                <Card.Heading className="nhsuk-heading-m">
                                    <Input
                                        id="search-organisations"
                                        label="Search organisations"
                                        name="search-organisations"
                                        type="search"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSearchChange(e);
                                            }
                                        }
                                        }
                                    />
                                </Card.Heading>
                                <Card.Description>
                                    <Fieldset>
                                        {organisations && organisations.length > 0 ? (
                                            organisations.map((organisation) => (
                                                <Details key={organisation._id}>
                                                    <Details.Summary>
                                                        {organisation.name}
                                                    </Details.Summary>
                                                    <Details.Text>
                                                        <p>
                                                            <strong>Address:</strong> {organisation.address}
                                                        </p>
                                                        <p>
                                                            <strong>Email:</strong> {organisation.email}
                                                        </p>
                                                        <p>
                                                            <strong>Phone:</strong> {organisation.telephone ? organisation.telephone : "-"}
                                                        </p>
                                                        <p>
                                                            <strong>Created:</strong> {new Date(organisation.createdAt ? organisation.createdAt : Date.now()).toLocaleDateString()}
                                                        </p>
                                                        <p>
                                                            <strong>Last Updated:</strong> {new Date(organisation.updatedAt ? organisation.updatedAt : Date.now()).toLocaleDateString()}
                                                        </p>
                                                        <div className="d-flex justify-content-between">
                                                            <Button
                                                                disabled={organisationDetailsLoading}
                                                                onClick={() =>
                                                                    handleEditOrganisation(organisation.id)
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                            {" "}
                                                            <Button
                                                                secondary

                                                                onClick={() => handleDelete(organisation.id)}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </Details.Text>
                                                </Details>
                                            ))
                                        ) : (
                                            null
                                        )}

                                    </Fieldset>
                                    {loading ? (
                                        <AppSpinner />
                                    ) : error ? (
                                        <p>{error}</p>
                                    ) : (
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            handlePageChange={handlePageChange}
                                        />

                                    )}

                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </div>
                    <div className="nhsuk-grid-column-one-half">
                        {editMode ? (
                            <Card id='edit-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Edit Organisation
                                        <InsetText>
                                            <p>
                                                This section allows you to edit an organisation. If you want to create a new organisation, please click the "Create Organisation" button below.
                                            </p>
                                            <Button
                                                onClick={() => {
                                                    setEditMode(false);
                                                }}
                                            >
                                                + Create Organisation
                                            </Button>

                                        </InsetText>


                                    </Card.Heading>
                                    <Card.Description>
                                        <Input
                                            id="name"
                                            label="Name"
                                            name="name"
                                            type="text"
                                            onChange={(e) => setUpdatedOrganisation({ ...updatedOrganisation, name: e.target.value })}
                                            value={updatedOrganisation.name ? updatedOrganisation.name : ''}
                                            required
                                        />
                                        <Input
                                            id="email"
                                            label="Email"
                                            name="email"
                                            type="email"
                                            onChange={(e) => setUpdatedOrganisation({ ...updatedOrganisation, email: e.target.value })}
                                            value={updatedOrganisation.email ? updatedOrganisation.email : ''}
                                            required
                                        />
                                        <Input
                                            id="telephone"
                                            label="Telephone"
                                            name="telephone"
                                            type="text"
                                            onChange={(e) => setUpdatedOrganisation({ ...updatedOrganisation, telephone: e.target.value })}
                                            value={updatedOrganisation.telephone ? updatedOrganisation.telephone : ''}
                                            required
                                        />

                                        <Input
                                            id="address"
                                            label="Address"
                                            name="address"
                                            type="text"
                                            onChange={(e) => setUpdatedOrganisation({ ...updatedOrganisation, address: e.target.value })}
                                            value={updatedOrganisation.address ? updatedOrganisation.address : ''}
                                            required
                                        />
                                        <Input
                                            id="postalCode"
                                            label="Postal Code"
                                            name="postalCode"
                                            type="text"
                                            onChange={(e) => setUpdatedOrganisation({ ...updatedOrganisation, postalCode: e.target.value })}
                                            value={updatedOrganisation.postalCode ? updatedOrganisation.postalCode : ''}
                                            required
                                        />
                                        <Input
                                            id="city"
                                            label="City"
                                            name="city"
                                            type="text"
                                            onChange={(e) => setUpdatedOrganisation({ ...updatedOrganisation, city: e.target.value })}
                                            value={updatedOrganisation.city ? updatedOrganisation.city : ''}
                                            required
                                        />
                                        <Input
                                            id="state"
                                            label="State"
                                            name="state"
                                            type="text"
                                            onChange={(e) => setUpdatedOrganisation({ ...updatedOrganisation, state: e.target.value })}
                                            value={updatedOrganisation.state ? updatedOrganisation.state : ''}
                                            required
                                        />
                                        <Input
                                            id="country"
                                            label="Country"
                                            name="country"
                                            type="text"
                                            onChange={(e) => setUpdatedOrganisation({ ...updatedOrganisation, country: e.target.value })}
                                            value={updatedOrganisation.country ? updatedOrganisation.country : ''}
                                            required
                                        />
                                        <Input
                                            id="website"
                                            label="Website"
                                            name="website"
                                            type="text"
                                            onChange={(e) => setUpdatedOrganisation({ ...updatedOrganisation, website: e.target.value })}
                                            value={updatedOrganisation.website ? updatedOrganisation.website : ''}
                                        />
                                        <Textarea
                                            id="description"
                                            label="Description"
                                            name="description"
                                            onChange={(e) => setUpdatedOrganisation({ ...updatedOrganisation, description: e.target.value })}
                                            value={updatedOrganisation.description ? updatedOrganisation.description : ''}
                                        />


                                        {(successCreate || successUpdate) ?
                                            <Callout
                                                label="Success"
                                                header="Success!"
                                                autoDismiss={true}
                                                dismissTime={5000}
                                                time={new Date().toLocaleTimeString()}
                                                title="Success!"
                                                message={successCreate ? "The organisation was created successfully." : "The organisation was updated successfully."}
                                                mode="success"
                                            />
                                            : null
                                        }

                                    </Card.Description>
                                    <Button
                                        disabled={organisationUpdateProcessing}
                                        onClick={handleUpdateOrganisation}
                                    >
                                        Save changes
                                    </Button>

                                </Card.Content>
                            </Card>
                        ) : (
                            <Card id='create-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Create a new Organisation
                                    </Card.Heading>
                                    <Card.Description>
                                        <Input
                                            id="name-create"
                                            label="Name"
                                            name="name-create"
                                            type="text"
                                            onChange={(e) => setNewOrganisation({ ...newOrganisation, name: e.target.value })}
                                            value={newOrganisation?.name}
                                            required
                                        />
                                        <Input
                                            id="email-create"
                                            label="Email"
                                            name="email-create"
                                            type="email"
                                            onChange={(e) => setNewOrganisation({ ...newOrganisation, email: e.target.value })}
                                            value={newOrganisation?.email}
                                            required
                                        />
                                        <Input
                                            id="telephone-create"
                                            label="Telephone"
                                            name="telephone-create"
                                            type="text"
                                            onChange={(e) => setNewOrganisation({ ...newOrganisation, telephone: e.target.value })}
                                            value={newOrganisation?.telephone}
                                            required
                                        />

                                        <Input
                                            id="address-create"
                                            label="Address"
                                            name="address-create"
                                            type="text"
                                            onChange={(e) => setNewOrganisation({ ...newOrganisation, address: e.target.value })}
                                            value={newOrganisation?.address}
                                            required
                                        />
                                        <Input
                                            id="postalCode-create"
                                            label="Postal Code"
                                            name="postalCode-create"
                                            type="text"
                                            onChange={(e) => setNewOrganisation({ ...newOrganisation, postalCode: e.target.value })}
                                            value={newOrganisation?.postalCode}
                                            required
                                        />
                                        <Input
                                            id="city-create"
                                            label="City"
                                            name="city"
                                            type="text-create"
                                            onChange={(e) => setNewOrganisation({ ...newOrganisation, city: e.target.value })}
                                            value={newOrganisation?.city}
                                            required
                                        />
                                        <Input
                                            id="state-create"
                                            label="State"
                                            name="state-create"
                                            type="text"
                                            onChange={(e) => setNewOrganisation({ ...newOrganisation, state: e.target.value })}
                                            value={newOrganisation?.state}
                                            required
                                        />
                                        <Input
                                            id="country-create"
                                            label="Country"
                                            name="country-create"
                                            type="text"
                                            onChange={(e) => setNewOrganisation({ ...newOrganisation, country: e.target.value })}
                                            value={newOrganisation?.country}
                                            required
                                        />
                                        <Input
                                            id="website-create"
                                            label="Website"
                                            name="website-create"
                                            type="text"
                                            onChange={(e) => setNewOrganisation({ ...newOrganisation, website: e.target.value })}
                                            value={newOrganisation?.website}
                                        />
                                        <Textarea
                                            id="description-create"
                                            label="Description"
                                            name="description-create"
                                            onChange={(e) => setNewOrganisation({ ...newOrganisation, description: e.target.value })}
                                            value={newOrganisation?.description}
                                        />




                                    </Card.Description>

                                    <Button
                                        disabled={organisationUpdateProcessing}
                                        onClick={handleCreateOrganisation}
                                    >
                                        Create Organisation
                                    </Button>

                                </Card.Content>
                            </Card>
                        )}

                    </div>

                </Container>
            </div>
        </React.Fragment>

    );
}






