import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FaUser } from "react-icons/fa";
import { Input, Button, InsetText, Select as SimpleSelect, DateInput, BackLink, Card, Details, ErrorSummary, SummaryList, Label, Radios, Checkboxes, Tag, ActionLink } from "nhsuk-react-components";
import { createPatient } from "../../actions/patientActions";
import { listOrganisations } from "../../actions/organisationActions";
import { listUsers } from "../../actions/userActions";
import { listEcgs } from "../../actions/ecgActions";
import { listSymptoms } from "../../actions/symptomActions";
import { listAetiologies } from "../../actions/aetiologyActions";
import { listFollowupCentres } from "../../actions/followupCentreActions";
import { listImplantCentres } from "../../actions/implantCentreActions";
import { listImplants } from "../../actions/implantActions";
import Select from 'react-select';
import { debounce } from 'lodash';
import Callout from "../../components/callout/Callout.component";
import Checklist from "../../components/checklist/Checklist.component";
import Modal from "../../components/modal/Modal.component";
import ImplantHistoryDrawer from "../../components/implantHistoryDrawer/ImplantHistoryDrawer.component";
import ImplantInstanceList from "../../components/implantInstanceList/ImplantInstanceList.component";
import SelectOther from "../../components/selectOther/SelectOther.component";
// PATIENT_CREATE_RESET
import { PATIENT_CREATE_RESET } from "../../constants/patientConstants";

import { Steps } from 'antd';



export default function PatientCreateScreen({ history }) {

    const dispatch = useDispatch();

    useEffect(() => {
        document.title = "Create Patient";
    }, []);


    // get current user details
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    // reset create patient
    useEffect(() => {
        dispatch({ type: PATIENT_CREATE_RESET });
    }, [dispatch]);



    // listen to userInfo and update identityUserId
    useEffect(() => {
        if (!userInfo.userId) return;
        setNewPatient({ ...newPatient, identityUserId: userInfo.userId });
    }, [userInfo]);



    // Steps 
    // 1. Patient Demographics
    // 2. Clinical Indications
    // 3. Implant Details
    const steps = [
        {
            title: 'Patient Demographics',
            description: 'Enter patient details',
        },
        {
            title: 'Clinical Indications',
            description: 'Enter clinical details',
        },
        {
            title: 'Implant Details',
            description: 'Enter implant details',
        },
    ];

    const [currentStep, setCurrentStep] = useState(0);

    const onStepChange = (value) => {
        setCurrentStep(value);
    };

    // get day month year
    const [dateofbirth, setdateofbirth] = useState(convertDate(Date.now()));
    const [generatorImplantDate, setgeneratorImplantDate] = useState(convertDate(Date.now()));

    // identity user email
    const [identityUserEmail, setIdentityUserEmail] = useState("");

    // currentIdentityUser
    const [currentIdentityUser, setCurrentIdentityUser] = useState({});

    // pass url
    const [passUrl, setPassUrl] = useState("");
    // selected collections
    const [selectedEcgs, setSelectedEcgs] = useState([]);
    const [selectedSymptoms, setSelectedSymptoms] = useState([]);
    const [selectedAetiologies, setSelectedAetiologies] = useState([]);
    const [selectedImplants, setSelectedImplants] = useState([]);
    const [selectedImplantCentres, setSelectedImplantCentres] = useState([]);
    const [selectedFollowupCentres, setSelectedFollowupCentres] = useState([]);

    // Implant History
    const [openDrawer, setOpenDrawer] = useState(false);

    // implant type selected boolean
    const [implantTypeSelected, setImplantTypeSelected] = useState(false);

    // show new IdentityUser form
    const [showNewIdentityUserForm, setShowNewIdentityUserForm] = useState(false);

    // Generic Modal
    const [genericModalShow, setGenericModalShow] = useState(false);
    const [genericModalContent, setGenericModalContent] = useState();
    const genericPropertiesToIgnore = ["id", "patientEcgs", "patientSymptoms", "patientAetiologies", "patientImplants", "patientImplantCentres", "patientFollowUpCentres", "patientConsents", "patient"];


    const openGenericModal = () => {
        setGenericModalShow(true);
    };

    const closeGenericModal = () => {
        setGenericModalShow(false);
    };

    // create patient 
    const patientCreate = useSelector((state) => state.patientCreate);
    const { loading: loadingCreate, error: errorCreate, success: successCreate, patient } = patientCreate;

    // load organisations
    const organisationList = useSelector((state) => state.organisationList);
    const { loading: loadingOrganisation, error: errorOrganisation, organisations } = organisationList;

    // load ecgs
    const ecgList = useSelector((state) => state.ecgList);
    const { loading: loadingEcg, error: errorEcg, ecgs } = ecgList;

    // load implants
    const implantList = useSelector((state) => state.implantList);
    const { loading: loadingImplant, error: errorImplant, implants } = implantList;

    // load symptoms
    const symptomList = useSelector((state) => state.symptomList);
    const { loading: loadingSymptom, error: errorSymptom, symptoms } = symptomList;

    // load aetiologies
    const aetiologyList = useSelector((state) => state.aetiologyList);
    const { loading: loadingAetiology, error: errorAetiology, aetiologies } = aetiologyList;

    // load followup centres
    const followupCentreList = useSelector((state) => state.followupCentreList);
    const { loading: loadingFollowupCentre, error: errorFollowupCentre, followupCentres } = followupCentreList;

    // load implant centres
    const implantCentreList = useSelector((state) => state.implantCentreList);
    const { loading: loadingImplantCentre, error: errorImplantCentre, implantCentres } = implantCentreList;

    // create shortUrl
    const shortUrlCreate = useSelector((state) => state.shortUrlCreate);
    const { loading: loadingShortUrlCreate, error: errorShortUrlCreate, success: successShortUrlCreate, shortUrl } = shortUrlCreate;

    // update 
    const userList = useSelector((state) => state.userList);
    const { loading: loadingUsers, error: errorUsers, users } = userList;

    // handle user filter
    const [userListFilter, setuserListFilter] = useState({ query: "", page: 1, filter: "UserName" });

    const [newPatient, setNewPatient] = useState({
        // set identityUserId to current user id
        identityUserId: userInfo?.userId,
        // set date of birth to today
        dateOfBirth: convertToDate(convertDate(Date.now())),
        // set gender to male 
        gender: "Male",
        // set mr conditional to false
        mrConditional: false,
        // set abandoned hardware to false
        abondonedHardware: false,
        // set implantation date to today
        generatorImplantDate: convertToDate(convertDate(Date.now())),
        // empty consentIds
        consentIds: [],
        // empty ecgIds
        ecgIds: [],
        // empty symptomIds
        symptomIds: [],
        // empty aetiologyIds
        aetiologyIds: [],
        // empty implantIds
        implantIds: [],
        // empty implantCentreIds
        implantCentreIds: [],
        // empty followUpCentreIds
        followUpCentreIds: [],
        // implant type
        implantType: "",
    });

    // handle user filter change
    const handleUserFilterChange = (e) => {
        const selectedFilter = e.target.value;
        setuserListFilter({ ...userListFilter, filter: selectedFilter });
    };

    // debounce the search text field
    // Debounce the search function
    const debouncedSearch = debounce((text) => {
        setuserListFilter({ ...userListFilter, query: text });
    }, 1000);


    // get patient details
    useEffect(() => {
        // reset pass url
        dispatch(listOrganisations());
        dispatch(listUsers());
        dispatch(listEcgs());
        dispatch(listSymptoms());
        dispatch(listAetiologies());
        dispatch(listFollowupCentres());
        dispatch(listImplantCentres());
        dispatch(listImplants({ limit: 500 }));
    }, [dispatch]);

    // listen to user filter changes
    useEffect(() => {
        dispatch(listUsers(userListFilter));
    }, [dispatch, userListFilter]);


    // function to convert date to day, month, year
    // from 2023-12-23T11:25:50Z like format
    // to {day:month, month:month, year:year} for react nhs date input
    function convertDate(date) {
        const dateObj = new Date(date);
        const day = dateObj.getDate();
        const month = dateObj.getMonth() + 1;
        const year = dateObj.getFullYear();
        return {
            day: day,
            month: month,
            year: year,
        };
    }


    // convert to date from {day:month, month:month, year:year}
    // to 2023-12-23T11:25:50Z like format
    function convertToDate(date) {
        try {
            const dateObj = new Date(date.year, date.month - 1, date.day);
            if (isNaN(dateObj.getTime())) {
                throw new Error("Invalid date");
            }
            return dateObj.toISOString();
        } catch (error) {
            // empty date field
            console.error(error);
            return null;
        }
    }
    // listen to selected ecgs
    useEffect(() => {
        if (selectedEcgs) {
            setNewPatient({ ...newPatient, ecgIds: selectedEcgs.map(ecg => ecg.value) });
        }
    }, [selectedEcgs]);

    // listen to selected symptoms
    useEffect(() => {
        if (selectedSymptoms) {
            setNewPatient({ ...newPatient, symptomIds: selectedSymptoms.map(symptom => symptom.value) });
        }
    }, [selectedSymptoms]);

    // listen to selected aetiologies
    useEffect(() => {
        if (selectedAetiologies) {
            setNewPatient({ ...newPatient, aetiologyIds: selectedAetiologies.map(aetiology => aetiology.value) });
        }
    }, [selectedAetiologies]);

    // listen to selected implants
    useEffect(() => {
        if (selectedImplants) {
            setNewPatient({ ...newPatient, implantIds: selectedImplants.map(implant => implant.value) });
        }
    }, [selectedImplants]);

    // listen to selected implant centres
    useEffect(() => {
        if (selectedImplantCentres) {
            setNewPatient({ ...newPatient, implantCentreIds: selectedImplantCentres.map(implantCentre => implantCentre.value) });
        }
    }, [selectedImplantCentres]);

    // listen to selected followup centres
    useEffect(() => {
        if (selectedFollowupCentres) {
            setNewPatient({ ...newPatient, followUpCentreIds: selectedFollowupCentres.map(followupCentre => followupCentre.value) });
        }
    }, [selectedFollowupCentres]);

    // listen to create patient success
    useEffect(() => {
        if (successCreate) {
            dispatch({ type: PATIENT_CREATE_RESET });
            history.push(`/patients`);
        }
    }, [history, successCreate, patient]);

    // listen to implant type
    useEffect(() => {
        if (newPatient?.implantType) {
            setImplantTypeSelected(true);
        } else {
            setImplantTypeSelected(false);
        }
    }, [newPatient?.implantType]);



    // handle create patient
    const handleCreatePatient = (e) => {
        e.preventDefault();
        // set list of consentIds to empty
        dispatch(createPatient(newPatient));
    };


    // handle display entity details in modal
    const handleEntityClick = (entity) => {
        if (!entity) return;
        setGenericModalContent(entity);
        openGenericModal();
    };

    // handle device list condition change
    const handleDeviceListConditionChange = (target) => {
        setNewPatient({ ...newPatient, mrConditional: target.isMRIConditional, abondonedHardware: target.isAbandonedHardware });
    };



    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>
                    <div className="nhsuk-grid-row">

                        <div className="welcome-title d-flex justify-content-between align-items-center">
                            <h3>Create a new Patient</h3>

                            <BackLink
                                asElement="a"
                                href="#"
                                onClick={() => history.goBack()}
                            >
                                Return back
                            </BackLink>

                        </div>
                        <div className="nhsuk-grid-column-one-half">
                            <div className="welcome-box">
                                <Steps
                                    current={currentStep}
                                    onChange={onStepChange}
                                    items={steps}
                                />
                                <div className="nhsuk-u-padding-top-3"></div>
                                <div className="welcome-content">
                                    <div className="welcome-text">
                                        <Card.Group>
                                            {currentStep === 0 && (
                                                <Card>
                                                    <Card.Content>
                                                        {errorCreate && (
                                                            <ErrorSummary
                                                                aria-labelledby="error-summary-title"
                                                                role="alert"
                                                                tabIndex={-1}
                                                            >
                                                                <ErrorSummary.Title id="error-summary-title">
                                                                    There is a problem
                                                                </ErrorSummary.Title>
                                                                <ErrorSummary.Body>
                                                                    <ul className="nhsuk-list nhsuk-list--bullet">
                                                                        {errorCreate.map((err) => (
                                                                            <li key={err}>
                                                                                {err}
                                                                            </li>
                                                                        ))}

                                                                    </ul>
                                                                </ErrorSummary.Body>
                                                            </ErrorSummary>
                                                        )
                                                        }

                                                        <Card.Heading className="nhsuk-heading-m">
                                                            <div className="icon-container ">
                                                                <FaUser size={30} />
                                                            </div>
                                                            New Patient: {newPatient?.forename} {newPatient?.surname}

                                                        </Card.Heading>
                                                        <p> This patient will be automatically linked to your account.</p>
                                                        <InsetText>
                                                            <SimpleSelect
                                                                label="Implant type"
                                                                selected={newPatient?.implantType}
                                                                name="implantType"
                                                                required
                                                                onChange={(e) => setNewPatient({ ...newPatient, implantType: e.target.value })}
                                                                value={newPatient?.implantType}
                                                            >
                                                                <SimpleSelect.Option
                                                                    value=""
                                                                >Select an option</SimpleSelect.Option>

                                                                <SimpleSelect.Option
                                                                    value="Cardiac implantable electronic device"
                                                                >Cardiac implantable electronic device</SimpleSelect.Option>
                                                                <SimpleSelect.Option
                                                                    value="Heart Valve"
                                                                >Heart Valve</SimpleSelect.Option>
                                                                <SimpleSelect.Option
                                                                    value="Coronary intervention"
                                                                >Coronary intervention</SimpleSelect.Option>
                                                                <SimpleSelect.Option
                                                                    value="Electrophysiology"
                                                                >Electrophysiology</SimpleSelect.Option>

                                                            </SimpleSelect>

                                                        </InsetText>
                                                        {implantTypeSelected && (

                                                            <Card.Description>

                                                                <Input
                                                                    id="input-nhsnumber"
                                                                    label="NHS Number"
                                                                    name="nhsNumber"
                                                                    value={newPatient?.nhsNumber}
                                                                    onChange={(e) => setNewPatient({ ...newPatient, nhsNumber: e.target.value })}
                                                                    required
                                                                />
                                                                <Input
                                                                    id="input-forname"
                                                                    label="Forename"
                                                                    name="forename"
                                                                    value={newPatient?.forename}
                                                                    onChange={(e) => setNewPatient({ ...newPatient, forename: e.target.value })}
                                                                    required={true}
                                                                />
                                                                <Input
                                                                    id="input-surname"
                                                                    label="Surname"
                                                                    name="surname"
                                                                    value={newPatient?.surname}
                                                                    onChange={(e) => setNewPatient({ ...newPatient, surname: e.target.value })}
                                                                    required={true}

                                                                />
                                                                <DateInput
                                                                    id="input-dateofbirth"
                                                                    label="Date of birth"
                                                                    name="dateOfBirth"
                                                                    value={dateofbirth}
                                                                    onChange={(e) => {
                                                                        // debounce the date of birth

                                                                        setdateofbirth(e.target.value);
                                                                        setNewPatient({ ...newPatient, dateOfBirth: convertToDate(e.target.value) });
                                                                    }}
                                                                    required={true}

                                                                />


                                                                <Checkboxes name="checkboxes">
                                                                    <Checkboxes.Box
                                                                        conditional={<p>If you prefer to link this patient to another identity account, use the dropdown below:</p>}
                                                                        checked={showNewIdentityUserForm}
                                                                        onChange={(e) => setShowNewIdentityUserForm(e.target.checked)}
                                                                    >
                                                                        Link to another Account?
                                                                    </Checkboxes.Box>
                                                                </Checkboxes>

                                                                {showNewIdentityUserForm &&
                                                                    <div>
                                                                        <Select
                                                                            isLoading={loadingUsers}
                                                                            isSearchable={true}
                                                                            isClearable={true}
                                                                            required={true}
                                                                            options={users?.map(user => ({ value: user.id, label: user.userName }))}
                                                                            // selected value is the newPatient.userId
                                                                            value={users?.map(user => ({ value: user.id, label: user.userName })).find(user => user.value === newPatient.identityUserId)}
                                                                            onInputChange={(text, actionMeta) => {
                                                                                if (actionMeta.action === 'input-change') {
                                                                                    debouncedSearch(text);
                                                                                }
                                                                            }}
                                                                            onChange={(selectedOption) => {
                                                                                setCurrentIdentityUser(users?.find(user => user.id === selectedOption?.value));
                                                                                setNewPatient({ ...newPatient, identityUserId: selectedOption?.value });
                                                                                setIdentityUserEmail(users?.map(user => ({ value: user.id, label: user.userName })).find(user => user.value === selectedOption?.value)?.label);
                                                                            }}
                                                                        />
                                                                        <div className="nhsuk-u-padding-top-3"></div>

                                                                        {(!loadingUsers && currentIdentityUser.userName) &&
                                                                            <Checklist heading="Account checklist">
                                                                                <Checklist.Item mode={currentIdentityUser?.userName ? "success" : "error"}>
                                                                                    Account created: {currentIdentityUser?.userName ? "Yes" : "No"}
                                                                                </Checklist.Item>
                                                                                <Checklist.Item mode={currentIdentityUser?.roles?.includes("UnauthorizedUser") ? "error" : "success"}>
                                                                                    User role setup: {currentIdentityUser?.roles?.includes("UnauthorizedUser") ? "No" : <Tag>{currentIdentityUser?.roles[0] || "N/A"}</Tag>}
                                                                                </Checklist.Item>
                                                                                <Checklist.Item mode={currentIdentityUser?.emailConfirmed ? "success" : "error"}>
                                                                                    Email confirmed: {currentIdentityUser?.emailConfirmed ? "Yes" : "No"}{" "}
                                                                                </Checklist.Item>
                                                                            </Checklist>
                                                                        }

                                                                        <Link to={newPatient.identityUserId ? `/profile/${newPatient.identityUserId}` : "#"} target="_blank">
                                                                            → Identity user details
                                                                        </Link>
                                                                    </div>
                                                                }

                                                                <Input
                                                                    id="input-email"
                                                                    label={
                                                                        <span>
                                                                            Email <a href="#input-email" onClick={() => setNewPatient({ ...newPatient, email: (showNewIdentityUserForm && identityUserEmail) ? identityUserEmail : userInfo.email })}>[Same as Identity]</a>

                                                                        </span>
                                                                    }
                                                                    name="email"
                                                                    value={newPatient?.email}
                                                                    onChange={(e) => setNewPatient({ ...newPatient, email: e.target.value })}
                                                                    required
                                                                />

                                                                <Input
                                                                    id="input-phone"
                                                                    label="Phone Number"
                                                                    name="phoneNumber"
                                                                    value={newPatient?.phoneNumber}
                                                                    onChange={(e) => setNewPatient({ ...newPatient, phoneNumber: e.target.value })}
                                                                />


                                                                <SimpleSelect
                                                                    label="Gender"
                                                                    selected={newPatient?.gender}
                                                                    name="gender"

                                                                    onChange={(e) => setNewPatient({ ...newPatient, gender: e.target.value })}
                                                                    required
                                                                >
                                                                    <SimpleSelect.Option
                                                                        value="Male"
                                                                    >Male</SimpleSelect.Option>
                                                                    <SimpleSelect.Option
                                                                        value="Female"
                                                                    >Female</SimpleSelect.Option>
                                                                    <SimpleSelect.Option
                                                                        value="Other"
                                                                    >Other</SimpleSelect.Option>
                                                                </SimpleSelect>


                                                                {(successCreate) ?
                                                                    <Callout
                                                                        label="Success"
                                                                        header="Success!"
                                                                        autoDismiss={true}
                                                                        dismissTime={5000}
                                                                        time={new Date().toLocaleTimeString()}
                                                                        message={"Patient created successfully!"}
                                                                        mode="success"
                                                                    />
                                                                    : null
                                                                }

                                                            </Card.Description>
                                                        )}

                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Button
                                                                type="submit"
                                                                disabled={loadingCreate}
                                                                onClick={(e) => {
                                                                    handleCreatePatient(e);
                                                                }}
                                                            >
                                                                Save
                                                            </Button>
                                                            <Button
                                                                disabled={!newPatient?.implantType && !newPatient?.nhsNumber || !newPatient?.forename || !newPatient?.surname}
                                                                secondary onClick={() => {
                                                                    setCurrentStep(1)
                                                                }
                                                                }>Next</Button>
                                                        </div>



                                                    </Card.Content>
                                                </Card>
                                            )}

                                            {currentStep === 1 && (
                                                <Card>
                                                    <Card.Content>
                                                        <Card.Heading className="nhsuk-heading-m">
                                                            Clinical Indications
                                                        </Card.Heading>
                                                        <Card.Description>
                                                            {errorCreate && (
                                                                <ErrorSummary
                                                                    aria-labelledby="error-summary-title"
                                                                    role="alert"
                                                                    tabIndex={-1}
                                                                >
                                                                    <ErrorSummary.Title id="error-summary-title">
                                                                        Please check the following
                                                                    </ErrorSummary.Title>
                                                                    <ErrorSummary.Body>
                                                                        <ul className="nhsuk-list nhsuk-list--bullet">
                                                                            {errorCreate.map((err) => (
                                                                                <li key={err}>
                                                                                    {err}
                                                                                </li>
                                                                            ))}

                                                                        </ul>
                                                                    </ErrorSummary.Body>
                                                                </ErrorSummary>
                                                            )
                                                            }
                                                            <Details>
                                                                <Details.Summary>Aetiologies</Details.Summary>
                                                                <Details.Text>
                                                                    <ul>
                                                                        {selectedAetiologies?.map(aetiology => (

                                                                            <li>
                                                                                <Link
                                                                                    to="#"
                                                                                    onClick={() => {
                                                                                        const aetiologyId = aetiology.value;
                                                                                        const selectedAetiology = aetiologies?.find(aetiology => aetiology.id === aetiologyId);
                                                                                        handleEntityClick(selectedAetiology);
                                                                                    }}
                                                                                >
                                                                                    {aetiology.label}
                                                                                </Link>
                                                                            </li>

                                                                        ))}
                                                                    </ul>
                                                                </Details.Text>
                                                            </Details>
                                                            <Select
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                options={aetiologies?.map(aetiology => ({ value: aetiology.id, label: aetiology.name }))}
                                                                // selected value from the newPatient
                                                                value={newPatient?.aetiologyIds ? (aetiologies?.map(aetiology => ({ value: aetiology.id, label: aetiology.name })).filter(aetiology => newPatient.aetiologyIds.includes(aetiology.value))) : ""}


                                                                isMulti
                                                                onChange={(selectedOption) => {
                                                                    setSelectedAetiologies(selectedOption);
                                                                }}
                                                            />
                                                            <div className="nhsuk-u-padding-top-3"></div>

                                                            <Details>
                                                                <Details.Summary>Symptoms</Details.Summary>
                                                                <Details.Text>
                                                                    <ul>
                                                                        {selectedSymptoms?.map(symptom => (

                                                                            <li>
                                                                                <Link
                                                                                    to="#"
                                                                                    onClick={() => {
                                                                                        const symptomId = symptom.value;
                                                                                        const selectedSymptom = symptoms?.find(symptom => symptom.id === symptomId);
                                                                                        handleEntityClick(selectedSymptom);
                                                                                    }}
                                                                                >
                                                                                    {symptom.label}
                                                                                </Link>
                                                                            </li>

                                                                        ))}
                                                                    </ul>
                                                                </Details.Text>
                                                            </Details>
                                                            <Select
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                options={symptoms?.map(symptom => ({ value: symptom.id, label: symptom.name }))}
                                                                // selected value is the newPatient.userId
                                                                value={newPatient?.symptomIds ? (symptoms?.map(symptom => ({ value: symptom.id, label: symptom.name })).filter(symptom => newPatient.symptomIds.includes(symptom.value))) : ""}
                                                                isMulti
                                                                onChange={(selectedOption) => {
                                                                    setSelectedSymptoms(selectedOption);
                                                                }}
                                                            />
                                                            <div className="nhsuk-u-padding-top-3"></div>

                                                            <Details>
                                                                <Details.Summary>ECGs</Details.Summary>
                                                                <Details.Text>
                                                                    <ul>
                                                                        {selectedEcgs?.map(ecg => (

                                                                            <li>
                                                                                <Link
                                                                                    to="#"
                                                                                    onClick={() => {
                                                                                        const ecgId = ecg.value;
                                                                                        const selectedEcg = ecgs?.find(ecg => ecg.id === ecgId);
                                                                                        handleEntityClick(selectedEcg);
                                                                                    }}
                                                                                >
                                                                                    {ecg.label}
                                                                                </Link>
                                                                            </li>

                                                                        ))}
                                                                    </ul>
                                                                </Details.Text>
                                                            </Details>

                                                            <Select
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                options={ecgs?.map(ecg => ({ value: ecg.id, label: ecg.name }))}
                                                                // selected value is the newPatient.userId
                                                                value={newPatient?.ecgIds ? (ecgs?.map(ecg => ({ value: ecg.id, label: ecg.name })).filter(ecg => newPatient.ecgIds.includes(ecg.value))) : ""}
                                                                isMulti
                                                                onChange={(selectedOption) => {
                                                                    setSelectedEcgs(selectedOption);
                                                                }}
                                                            />

                                                            {(successCreate) ?
                                                                <Callout
                                                                    label="Success"
                                                                    header="Success!"
                                                                    autoDismiss={true}
                                                                    dismissTime={5000}
                                                                    time={new Date().toLocaleTimeString()}
                                                                    title="Success!"
                                                                    message={"Patient created successfully!"}
                                                                    mode="success"
                                                                />
                                                                : null
                                                            }


                                                        </Card.Description>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Button
                                                                type="submit"
                                                                disabled={loadingCreate}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    dispatch(createPatient(newPatient));
                                                                }}
                                                            >   Save
                                                            </Button>
                                                            <Button secondary onClick={() => setCurrentStep(2)}>Next</Button>
                                                        </div>

                                                    </Card.Content>
                                                </Card>
                                            )}

                                            {currentStep === 2 && (
                                                <Card>
                                                    <Card.Content>
                                                        <Card.Heading className="nhsuk-heading-m">
                                                            Implant Details
                                                        </Card.Heading>

                                                        <Card.Description>
                                                            {errorCreate && (
                                                                <ErrorSummary
                                                                    aria-labelledby="error-summary-title"
                                                                    role="alert"
                                                                    tabIndex={-1}
                                                                >
                                                                    <ErrorSummary.Title id="error-summary-title">
                                                                        Please check the following
                                                                    </ErrorSummary.Title>
                                                                    <ErrorSummary.Body>
                                                                        <ul className="nhsuk-list nhsuk-list--bullet">
                                                                            {errorCreate.map((err) => (
                                                                                <li key={err}>
                                                                                    {err}
                                                                                </li>
                                                                            ))}

                                                                        </ul>
                                                                    </ErrorSummary.Body>
                                                                </ErrorSummary>
                                                            )
                                                            }
                                                            <ImplantInstanceList
                                                                patientNHSNumber={newPatient.nhsNumber}
                                                                patientFullName={newPatient.forename ? `${newPatient.forename} ${newPatient.surname}` : ""}
                                                                onDeviceListConditionChange={(targetValue) => { handleDeviceListConditionChange(targetValue) }
                                                                }

                                                            />

                                                            <Checkboxes name="checkboxes" hint="Implant information.">

                                                                <Checkboxes.Box
                                                                    conditional={<p>MR conditionality applies.</p>}
                                                                    checked={newPatient?.mrConditional}
                                                                    onChange={(e) => setNewPatient({ ...newPatient, mrConditional: e.target.checked })}
                                                                >
                                                                    MR Conditional?
                                                                </Checkboxes.Box>
                                                                <Checkboxes.Box
                                                                    conditional={<p>The hardware has been abandoned.</p>}
                                                                    checked={newPatient?.abondonedHardware}
                                                                    onChange={(e) => setNewPatient({ ...newPatient, abondonedHardware: e.target.checked })}
                                                                >
                                                                    Abandoned Hardware?
                                                                </Checkboxes.Box>
                                                                <Checkboxes.Box
                                                                    conditional={<p>Active High Voltage Therapy.</p>}
                                                                    checked={newPatient?.activeHighVoltageTherapy}
                                                                    onChange={(e) => setNewPatient({ ...newPatient, activeHighVoltageTherapy: e.target.checked })}
                                                                >
                                                                    Active High Voltage Therapy?
                                                                </Checkboxes.Box>
                                                                <Checkboxes.Box
                                                                    conditional={<p>Patient received appropriate HV therapy.</p>}
                                                                    checked={newPatient?.patientReceivedAppropriateHVTherapy}
                                                                    onChange={(e) => setNewPatient({ ...newPatient, patientReceivedAppropriateHVTherapy: e.target.checked })}
                                                                >
                                                                    Patient received appropriate HV therapy?
                                                                </Checkboxes.Box>
                                                                <Checkboxes.Box
                                                                    conditional={<p>Patient received inappropriate HV therapy.</p>}
                                                                    checked={newPatient?.patientReceivedInappropriateHVTherapy}
                                                                    onChange={(e) => setNewPatient({ ...newPatient, patientReceivedInappropriateHVTherapy: e.target.checked })}
                                                                >
                                                                    Patient received inappropriate HV therapy?
                                                                </Checkboxes.Box>
                                                                <Checkboxes.Box
                                                                    conditional={<p>Patient under active advisory.</p>}
                                                                    checked={newPatient?.patientUnderActiveAdvisory}
                                                                    onChange={(e) => setNewPatient({ ...newPatient, patientUnderActiveAdvisory: e.target.checked })}
                                                                >
                                                                    Patient under active advisory?
                                                                </Checkboxes.Box>

                                                            </Checkboxes>
                                                            <SelectOther
                                                                id="bradyprogrammedmode"
                                                                label="Brady Programmed Mode"
                                                                name="bradyProgrammedMode"
                                                                value={newPatient?.bradyProgrammedMode}
                                                                onChange={(e) => setNewPatient({ ...newPatient, bradyProgrammedMode: e.target.value })}
                                                            >
                                                                <SimpleSelect.Option value="">Select an option</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="AAIR">AAIR</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="VVI">VVI</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="DDD">DDD</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="AOO">AOO</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="VDD">VDD</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="DOO">DOO</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="VVI">VVI</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="VVT">VVT</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="VDD">VDD</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="VVI">VVI</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="VVT">VVT</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="VDD">VDD</SimpleSelect.Option>

                                                            </SelectOther>

                                                            <div className="nhsuk-u-padding-top-3"></div>


                                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                                <Input
                                                                    id="input-bradyprogrammedlowerrate"
                                                                    label="Brady Programmed Lower Rate"
                                                                    name="bradyProgrammedLowerRate"
                                                                    type="number"
                                                                    value={newPatient?.bradyProgrammedLowerRate}
                                                                    onChange={(e) => setNewPatient({ ...newPatient, bradyProgrammedLowerRate: e.target.value })}
                                                                />
                                                                <Input
                                                                    id="input-tachytherapyzonerate"
                                                                    label="Tachy Therapy Zone Rate"
                                                                    name="tachyTherapyZoneRate"
                                                                    type="number"
                                                                    value={newPatient?.tachyTherapyZoneRate}
                                                                    onChange={(e) => setNewPatient({ ...newPatient, tachyTherapyZoneRate: e.target.value })}
                                                                />
                                                            </div>


                                                            <Details>
                                                                <Details.Summary>Implant Centres</Details.Summary>
                                                                <Details.Text>
                                                                    <ul>
                                                                        {selectedImplantCentres?.map(implantCentre => (

                                                                            <li>
                                                                                <Link
                                                                                    to="#"
                                                                                    onClick={() => {
                                                                                        const implantCentreId = implantCentre.value;
                                                                                        const selectedImplantCentre = implantCentres?.find(implantCentre => implantCentre.id === implantCentreId);
                                                                                        handleEntityClick(selectedImplantCentre);
                                                                                    }}
                                                                                >
                                                                                    {implantCentre.label}
                                                                                </Link>

                                                                            </li>

                                                                        ))}
                                                                    </ul>
                                                                </Details.Text>
                                                            </Details>

                                                            <Select
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                options={implantCentres?.map(implantCentre => ({ value: implantCentre.id, label: implantCentre.name }))}
                                                                // selected value is selectedImplantCentres
                                                                value={newPatient?.implantCentreIds ? (implantCentres?.map(implantCentre => ({ value: implantCentre.id, label: implantCentre.name })).filter(implantCentre => newPatient.implantCentreIds.includes(implantCentre.value))) : ""}
                                                                isMulti
                                                                onChange={(selectedOption) => {
                                                                    setSelectedImplantCentres(selectedOption);
                                                                }}

                                                            />

                                                            <div className="nhsuk-u-padding-top-3"></div>
                                                            <Details>
                                                                <Details.Summary>Followup Centres</Details.Summary>
                                                                <Details.Text>
                                                                    <ul>
                                                                        {selectedFollowupCentres?.map(followupCentre => (

                                                                            <li>
                                                                                <Link
                                                                                    to="#"
                                                                                    onClick={() => {
                                                                                        const followupCentreId = followupCentre.value;
                                                                                        const selectedFollowupCentre = followupCentres?.find(followupCentre => followupCentre.id === followupCentreId);
                                                                                        handleEntityClick(selectedFollowupCentre);
                                                                                    }}
                                                                                >
                                                                                    {followupCentre.label}
                                                                                </Link>
                                                                            </li>

                                                                        ))}
                                                                    </ul>
                                                                </Details.Text>
                                                            </Details>


                                                            <Select
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                options={followupCentres?.map(followupCentre => ({ value: followupCentre.id, label: followupCentre.name }))}
                                                                // selected value is the newPatient
                                                                value={newPatient.followUpCentreIds ? (followupCentres?.map(followupCentre => ({ value: followupCentre.id, label: followupCentre.name })).filter(followupCentre => newPatient.followUpCentreIds.includes(followupCentre.value))) : ""}

                                                                isMulti
                                                                onChange={(selectedOption) => {
                                                                    setSelectedFollowupCentres(selectedOption);
                                                                }}
                                                            />
                                                            <InsetText
                                                                id="implant-history"
                                                            >
                                                                <p>Add a note</p>

                                                                <ActionLink
                                                                    href="#implant-history"
                                                                    onClick={() => {
                                                                        if (newPatient.nhsNumber) {
                                                                            setOpenDrawer(true);
                                                                        } else {
                                                                            alert("Please provide a valid NHS number");
                                                                        }
                                                                    }}
                                                                >
                                                                    Notes
                                                                </ActionLink>
                                                            </InsetText>
                                                        </Card.Description>
                                                        <Button
                                                            type="submit"
                                                            disabled={loadingCreate}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                dispatch(createPatient(newPatient));
                                                            }}
                                                        >   Save
                                                        </Button>

                                                    </Card.Content>
                                                </Card>
                                            )}

                                        </Card.Group>


                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                    <Modal show={genericModalShow} handleClose={closeGenericModal}>
                        {genericModalContent && (
                            <SummaryList>
                                {
                                    Object.entries(genericModalContent).map(([key, value]) => (
                                        !genericPropertiesToIgnore.includes(key) &&
                                        <SummaryList.Row>
                                            <SummaryList.Key>
                                                {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                                            </SummaryList.Key>
                                            <SummaryList.Value>
                                                {value ? value.toString() : "-"}
                                            </SummaryList.Value>
                                        </SummaryList.Row>
                                    ))
                                }

                            </SummaryList>

                        )}
                        <Button
                            type="submit"
                            onClick={(e) => {
                                e.preventDefault();
                                closeGenericModal();
                            }
                            }
                        >   Close
                        </Button>
                    </Modal>
                    <ImplantHistoryDrawer
                        patientNHSNumber={newPatient.nhsNumber || ""}
                        patientFullName={newPatient.forename ? `${newPatient.forename} ${newPatient.surname}` : ""}
                        isOpen={openDrawer}
                        closeDrawer={() => setOpenDrawer(false)}
                    />

                </Container>
            </div >
        </React.Fragment >
    );
}




