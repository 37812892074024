import {

    AETIOLOGY_LIST_REQUEST,
    AETIOLOGY_LIST_SUCCESS,
    AETIOLOGY_LIST_FAIL,

    AETIOLOGY_DETAILS_REQUEST,
    AETIOLOGY_DETAILS_SUCCESS,
    AETIOLOGY_DETAILS_FAIL,

    AETIOLOGY_DELETE_REQUEST,
    AETIOLOGY_DELETE_SUCCESS,
    AETIOLOGY_DELETE_FAIL,

    AETIOLOGY_CREATE_REQUEST,
    AETIOLOGY_CREATE_SUCCESS,
    AETIOLOGY_CREATE_FAIL,
    AETIOLOGY_CREATE_RESET,

    AETIOLOGY_UPDATE_REQUEST,
    AETIOLOGY_UPDATE_SUCCESS,
    AETIOLOGY_UPDATE_FAIL,
    AETIOLOGY_UPDATE_RESET,

    AETIOLOGY_COUNT_SUCCESS,
    AETIOLOGY_COUNT_REQUEST,
    AETIOLOGY_COUNT_FAIL,

    SET_CURRENT_PAGE,

} from "../constants/aetiologyConstants";

const initialState = {
    aetiologies: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const aetiologyListReducer = (
    state = {
        aetiologies: []
    }, action) => {
    switch (action.type) {
        case AETIOLOGY_LIST_REQUEST:
            return { loading: true, aetiologies: [] };
        case AETIOLOGY_LIST_SUCCESS:
            // our state will have a list of aetiologies after the request
            return {
                loading: false,
                aetiologies: action.payload.aetiologies,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case AETIOLOGY_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const aetiologyCountReducer = (state = { aetiologyCounts: [] }, action) => {
  switch (action.type) {
    case AETIOLOGY_COUNT_REQUEST:
      return { loading: true, aetiologyCounts: [] };
    case AETIOLOGY_COUNT_SUCCESS:
      return { loading: false, aetiologyCounts: action.payload };
    case AETIOLOGY_COUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const aetiologyDetailsReducer = (
    state = {
        aetiology: { comments: [] }
    }, action) => {
    switch (action.type) {
        case AETIOLOGY_DETAILS_REQUEST:
            return { loading: true, ...state };
        case AETIOLOGY_DETAILS_SUCCESS:
            // our state will have a list of aetiologies after the request
            return { loading: false, aetiology: action.payload };
        case AETIOLOGY_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const aetiologyDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case AETIOLOGY_DELETE_REQUEST:
            return { loading: true }

        case AETIOLOGY_DELETE_SUCCESS:
            return { loading: false, success: true }

        case AETIOLOGY_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const aetiologyCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case AETIOLOGY_CREATE_REQUEST:
            return { loading: true }

        case AETIOLOGY_CREATE_SUCCESS:
            return { loading: false, success: true, aetiology: action.payload }

        case AETIOLOGY_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case AETIOLOGY_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const aetiologyUpdateReducer = (state = { aetiology: {} }, action) => {
    switch (action.type) {
        case AETIOLOGY_UPDATE_REQUEST:
            return { loading: true }

        case AETIOLOGY_UPDATE_SUCCESS:
            return { loading: false, success: true, aetiology: action.payload }

        case AETIOLOGY_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case AETIOLOGY_UPDATE_RESET:
            return { aetiology: {} }

        default:
            return state
    }
}