import {

    FOLLOWUP_CENTRE_LIST_REQUEST,
    FOLLOWUP_CENTRE_LIST_SUCCESS,
    FOLLOWUP_CENTRE_LIST_FAIL,

    FOLLOWUP_CENTRE_DETAILS_REQUEST,
    FOLLOWUP_CENTRE_DETAILS_SUCCESS,
    FOLLOWUP_CENTRE_DETAILS_FAIL,

    FOLLOWUP_CENTRE_DELETE_REQUEST,
    FOLLOWUP_CENTRE_DELETE_SUCCESS,
    FOLLOWUP_CENTRE_DELETE_FAIL,

    FOLLOWUP_CENTRE_CREATE_REQUEST,
    FOLLOWUP_CENTRE_CREATE_SUCCESS,
    FOLLOWUP_CENTRE_CREATE_FAIL,
    FOLLOWUP_CENTRE_CREATE_RESET,

    FOLLOWUP_CENTRE_UPDATE_REQUEST,
    FOLLOWUP_CENTRE_UPDATE_SUCCESS,
    FOLLOWUP_CENTRE_UPDATE_FAIL,
    FOLLOWUP_CENTRE_UPDATE_RESET,

    FOLLOWUP_CENTRE_COUNT_SUCCESS,
    FOLLOWUP_CENTRE_COUNT_REQUEST,
    FOLLOWUP_CENTRE_COUNT_FAIL,

    SET_CURRENT_PAGE,

} from "../constants/followupCentreConstants";

const initialState = {
    followupCentres: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const followupCentreListReducer = (
    state = {
        followupCentres: []
    }, action) => {
    switch (action.type) {
        case FOLLOWUP_CENTRE_LIST_REQUEST:
            return { loading: true, followupCentres: [] };
        case FOLLOWUP_CENTRE_LIST_SUCCESS:
            // our state will have a list of followupCentres after the request
            return {
                loading: false,
                followupCentres: action.payload.followupCentres,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case FOLLOWUP_CENTRE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const followupCentreCountReducer = (state = { followupCentreCounts: [] }, action) => {
  switch (action.type) {
    case FOLLOWUP_CENTRE_COUNT_REQUEST:
      return { loading: true, followupCentreCounts: [] };
    case FOLLOWUP_CENTRE_COUNT_SUCCESS:
      return { loading: false, followupCentreCounts: action.payload };
    case FOLLOWUP_CENTRE_COUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const followupCentreDetailsReducer = (
    state = {
        followupCentre: { comments: [] }
    }, action) => {
    switch (action.type) {
        case FOLLOWUP_CENTRE_DETAILS_REQUEST:
            return { loading: true, ...state };
        case FOLLOWUP_CENTRE_DETAILS_SUCCESS:
            // our state will have a list of followupCentres after the request
            return { loading: false, followupCentre: action.payload };
        case FOLLOWUP_CENTRE_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const followupCentreDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case FOLLOWUP_CENTRE_DELETE_REQUEST:
            return { loading: true }

        case FOLLOWUP_CENTRE_DELETE_SUCCESS:
            return { loading: false, success: true }

        case FOLLOWUP_CENTRE_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const followupCentreCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case FOLLOWUP_CENTRE_CREATE_REQUEST:
            return { loading: true }

        case FOLLOWUP_CENTRE_CREATE_SUCCESS:
            return { loading: false, success: true, followupCentre: action.payload }

        case FOLLOWUP_CENTRE_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case FOLLOWUP_CENTRE_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const followupCentreUpdateReducer = (state = { followupCentre: {} }, action) => {
    switch (action.type) {
        case FOLLOWUP_CENTRE_UPDATE_REQUEST:
            return { loading: true }

        case FOLLOWUP_CENTRE_UPDATE_SUCCESS:
            return { loading: false, success: true, followupCentre: action.payload }

        case FOLLOWUP_CENTRE_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case FOLLOWUP_CENTRE_UPDATE_RESET:
            return { followupCentre: {} }

        default:
            return state
    }
}