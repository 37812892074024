import axios from 'axios';

import {
    IMPLANT_LIST_REQUEST,
    IMPLANT_LIST_SUCCESS,
    IMPLANT_LIST_FAIL,

    IMPLANT_DETAILS_REQUEST,
    IMPLANT_DETAILS_SUCCESS,
    IMPLANT_DETAILS_FAIL,

    IMPLANT_DELETE_REQUEST,
    IMPLANT_DELETE_SUCCESS,
    IMPLANT_DELETE_FAIL,

    IMPLANT_CREATE_REQUEST,
    IMPLANT_CREATE_SUCCESS,
    IMPLANT_CREATE_FAIL,

    IMPLANT_UPDATE_REQUEST,
    IMPLANT_UPDATE_SUCCESS,
    IMPLANT_UPDATE_FAIL,

    IMPLANT_REQUEST,
    IMPLANT_FAIL,
    IMPLANT_RESET,
    IMPLANT_SUCCESS,

    IMPLANT_COUNT_SUCCESS,
    IMPLANT_COUNT_REQUEST,
    IMPLANT_COUNT_FAIL,

    IMPLANT_DTO_FAIL,
    IMPLANT_DTO_REQUEST,
    IMPLANT_DTO_SUCCESS,
    IMPLANT_DTO_RESET,

    SET_CURRENT_PAGE,
    

} from "../constants/implantConstants";

// use dispatch to dispatch the request and throw it into the reducer
export const listImplants = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: IMPLANT_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        // get the filter
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
            },
            params
        }
        const { data } = await axios.get('/api/v1/implant/all', config);

        dispatch({
            type: IMPLANT_LIST_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: IMPLANT_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later implants",
        });
    }
}

// count implants
export const countImplants = () => async (dispatch, getState) => {
    try {
      dispatch({ type: IMPLANT_COUNT_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      }
      const { data } = await axios.get('/api/v1/implant/count', config);
  
      dispatch({
        type: IMPLANT_COUNT_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: IMPLANT_COUNT_FAIL,
        payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : "Failed to fetch implant counts",
      });
    }
  }
  


export const getImplantDetails = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: IMPLANT_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
            }
        }
        const { data } = await axios.get(`/api/v1/implant/${_id}`, config);

        dispatch({
            type: IMPLANT_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }
        dispatch({
            type: IMPLANT_DETAILS_FAIL,
            payload: errorMessages,
        });
    }
}

// get implant by barcode
export const getImplantDetailsByBarcode = (barcode) => async (dispatch, getState) => {
    try {
        dispatch({ type: IMPLANT_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
            }
        }
        const { data } = await axios.get(`/api/v1/implant/barcode/${barcode}`, config);

        dispatch({
            type: IMPLANT_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }
        dispatch({
            type: IMPLANT_DETAILS_FAIL,
            payload: errorMessages,
        });
    }
}




export const loadImplantDto = ( ) => async (dispatch, getState) => {

    try {
        dispatch({
            type: IMPLANT_DTO_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const data = await axios.get(
            `/api/v1/implant/implantdto`,
            config
        )

        dispatch({
            type: IMPLANT_DTO_SUCCESS,
            payload: data,
        })

    }

    catch (error) {
        dispatch({
            type: IMPLANT_DTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const deleteImplant = (_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: IMPLANT_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/v1/implant/${_id}/`,
            config
        )

        dispatch({
            type: IMPLANT_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: IMPLANT_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createImplant = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: IMPLANT_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const data = await axios.post(
            `/api/v1/implant/`,
            changes,
            config
        )

        dispatch({
            type: IMPLANT_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }
        dispatch({
            type: IMPLANT_CREATE_FAIL,
            payload: errorMessages,
        })
    }
}

// create or update implant
export const createOrUpdateImplant = (barcode, implant) => async (dispatch, getState) => {
    try {
        dispatch({
            type: IMPLANT_CREATE_REQUEST   
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/v1/implant/${barcode}/`,
            implant,
            config
        )

        dispatch({
            type: IMPLANT_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        }
        else {
            errorMessages.push(error.message);
        }
        dispatch({
            type: IMPLANT_CREATE_FAIL,
            payload: errorMessages,
        })
    }
}


const loadImplantBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
    });
};

// make implant using a local implant
export const makeImplant = (file, implantId, apiEndPoint, apiKey) => async (dispatch, getState) => {

    const implant = await loadImplantBase64(file);
    const implant_input = JSON.stringify({ "implant": implant });
    const {
        userLogin: { userInfo },
    } = getState()

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }

    const data = await axios.post(
        apiEndPoint,
        implant_input,
        config
    )
    try {
        dispatch({
            type: IMPLANT_RESET
        })
        await axios({
            method: "POST",
            url: apiEndPoint,

            data: JSON.stringify({ "data": implant }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
                dispatch({
                    type: IMPLANT_SUCCESS
                });
                if (implantId && response.data) {
                    dispatch(updateImplant(implantId, { implant: response.data }));
                }
            })
            .catch(function (error) {
                dispatch({
                    type: IMPLANT_FAIL
                });
                console.log(error.message);
            });
    }

    catch (error) {
        dispatch({
            type: IMPLANT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}


export const updateImplant = (_id, changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: IMPLANT_UPDATE_REQUEST
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const response = await axios.patch(
            `/api/v1/implant/${_id}/`,
            changes,
            config
        );

        dispatch({
            type: IMPLANT_UPDATE_SUCCESS,
            payload: response.data, // Assuming the updated implant data is returned
        });

        dispatch({
            type: IMPLANT_DETAILS_SUCCESS,
            payload: response.data, // Assuming the updated implant data is returned
        });
    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }
        dispatch({
            type: IMPLANT_UPDATE_FAIL,
            payload: errorMessages,
        });
    }
};
