import React, { useState, useEffect, CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBar from "@ramonak/react-progress-bar";

import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Table, ActionLink, Label, Tag, Input, Fieldset, Radios, BackLink, Card, Details, Button, DateInput, InsetText, Textarea, ErrorSummary, Checkboxes, Select } from 'nhsuk-react-components';
// createPatient action
import { createPatient } from '../../actions/patientActions';
// implant create action
import { createOrUpdateImplant, createImplant } from '../../actions/implantActions';
// patientDto action
import { loadPatientDto } from '../../actions/patientActions';
// implantDto action
import { loadImplantDto } from '../../actions/implantActions';
// constants 
import { PATIENT_CREATE_RESET } from '../../constants/patientConstants';
import { IMPLANT_CREATE_RESET } from '../../constants/implantConstants';

import Pagination from '../../components/pagination/Pagination.component';
import AppSpinner from '../../components/spinner/AppSpinner.component';

import Callout from '../../components/callout/Callout.component';

import { useCSVReader } from 'react-papaparse';
import { styles } from '../../utils/functions';
import axios from 'axios';


export default function DataImportExportScreen({ history }) {

    // use react hook Dispatch to dispatch actions
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = "Data Import/Export"; 
    }, []);


    const { CSVReader } = useCSVReader();

    // Mapping tables 
    const [patientMappingTable, setPatientMappingTable] = useState();
    const [implantMappingTable, setImplantMappingTable] = useState();
    const [csvRows, setCsvRows] = useState();
    const [csvHeaders, setCsvHeaders] = useState();
    const [deviceMappings, setDeviceMappings] = useState([]);
    const [newImplant, setNewImplant] = useState({});
    const [override, setOverride] = useState(true);
    const [currentProgress, setCurrentProgress] = useState(0);
    const [importLogs, setImportLogs] = useState([]);
    const [currentRecord, setCurrentRecord] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [stopImport, setStopImport] = useState(false);
    const [importing, setImporting] = useState(false);

    // get the userLogin state from the redux store using useSelector
    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;

    // get the patientCreate state from the redux store using useSelector
    const patientCreate = useSelector(state => state.patientCreate);
    const { loading, error, success: successCreate, patient: createdPatient } = patientCreate;

    // get the implantCreate state from the redux store using useSelector
    const implantCreate = useSelector(state => state.implantCreate);
    const { loading: implantLoading, error: implantError, success: implantSuccess, implant: createdImplant } = implantCreate;

    // get implantCreateOrUpdate state from the redux store using useSelector
    const implantCreateOrUpdate = useSelector(state => state.implantCreateOrUpdate);
    const { loading: implantCreateOrUpdateLoading, error: implantCreateOrUpdateError, success: implantCreateOrUpdateSuccess, implant: createdOrUpdatedImplant } = implantCreateOrUpdate;

    // get the patientDto state from the redux store using useSelector
    const patientDto = useSelector(state => state.patientDto);
    const { loading: patientDtoLoading, error: patientDtoError, success: patientDtoSuccess, patientDto: newPatientDto } = patientDto;

    // get implantDto state from the redux store using useSelector
    const implantDto = useSelector(state => state.implantDto);
    const { loading: implantDtoLoading, error: implantDtoError, success: implantDtoSuccess, implantDto: newImplantDto } = implantDto;

    // get Dtos from the redux store
    useEffect(() => {
        dispatch(loadPatientDto());
        dispatch(loadImplantDto());
    }, [dispatch]);

    useEffect(() => {
        if (stopImport) {
            // Add any cleanup or stop import logic here
            console.log('Import stopped!');
        }
    }, [stopImport]);
    

    // Function to generate a table of mappings between the dto and csv rows
    // The table consists of csv headers on the left and <Select> component of dto headers on the right
    const generateMappingTable = (dto, csvHeaders) => {
        const table = [];

        if (!dto || !csvHeaders || csvHeaders.length === 0 || !dto?.data) {
            alert('No data to import');
            return table;
        }

        // Array to store the mappings
        const deviceMappings = [];

        // Get the properties of the dto
        const dtoProperties = Object.keys(dto?.data);

        // Add table header
        table.push(
            <tr key="header">
                <th>Property</th>
                <th>CSV Header Columns</th>
                <th>Default Value (if not mapped)</th>
            </tr>
        );

        for (let i = 0; i < dtoProperties.length; i++) {
            const dtoProperty = dtoProperties[i];

            // Create a mapping object
            const mapping = {
                dtoProperty: dtoProperty,
                csvHeader: '',
                defaultValue: '',
            };

            // Add the mapping to the array
            deviceMappings.push(mapping);

            const handleCsvHeaderChange = (e) => {
                // Update the csvHeader property of the mapping object
                mapping.csvHeader = e.target.value.trim();

                // Update the array of mappings with the same property
                setDeviceMappings((prevMappings) => {
                    const updatedMappings = [...prevMappings];
                    const mappingIndex = updatedMappings.findIndex((m) => m.dtoProperty === dtoProperty);
                    updatedMappings[mappingIndex] = mapping;
                    return updatedMappings;
                });
            };

            const handleDefaultValueChange = (e) => {
                // Update the defaultValue property of the mapping object
                mapping.defaultValue = e.target.value.trim();

                // Update the array of mappings with the same property
                setDeviceMappings((prevMappings) => {
                    const updatedMappings = [...prevMappings];
                    const mappingIndex = updatedMappings.findIndex((m) => m.dtoProperty === dtoProperty);
                    updatedMappings[mappingIndex] = mapping;
                    return updatedMappings;
                });
            };

            table.push(
                <tr key={i}>
                    <td>
                        {dtoProperty} <br /> <small>{dto?.data[dtoProperty]}</small>
                    </td>
                    <td>
                        <Select
                            onChange={handleCsvHeaderChange}
                        >
                            <Select.Option value="">Not mapped</Select.Option>
                            {csvHeaders.map((csvHeader, index) => (
                                <Select.Option
                                    key={index}
                                    value={csvHeader.trim()}
                                >
                                    {csvHeader}
                                </Select.Option>
                            ))}
                        </Select>
                    </td>
                    <td>
                        <Input
                            type="text"
                            name="default"
                            // show the default value from the dto
                            onChange={handleDefaultValueChange}
                        />
                    </td>
                </tr>
            );
        }

        // Add the deviceMappings array to the existing deviceMappings state
        setDeviceMappings([...deviceMappings, ...deviceMappings]);

        return table;
    }

    const createImplantRecord = async (deviceMappings) => {
        if (!deviceMappings || deviceMappings.length === 0 || !csvRows || csvRows.length === 0) {
            return;
        }

        setImporting(true);
    
        const totalRows = csvRows.length;
    
        setTotalRecords(totalRows);
        setCurrentRecord(0);
        setCurrentProgress(0);
        setImportLogs([]);
    
        const promises = csvRows.map(async (csvRow, rowIndex) => {
            if (stopImport) {
                setImporting(false);
                throw new Error('Import stopped');
            }
    
            const rowDict = {};
    
            for (let i = 0; i < csvHeaders.length; i++) {
                const header = csvHeaders[i]?.trim();
                let value = csvRow[i]?.trim();
    
                if (value?.toLowerCase() === "true") {
                    value = true;
                } else if (value?.toLowerCase() === "false") {
                    value = false;
                }
    
                rowDict[header] = value;
            }
    
            const newImplant = {};
    
            deviceMappings.forEach((mapping) => {
                const csvHeader = mapping.csvHeader.trim();
                const dtoProperty = mapping.dtoProperty;
                let defaultValue = mapping.defaultValue.trim();
    
                if (defaultValue.toLowerCase() === "true") {
                    defaultValue = true;
                } else if (defaultValue.toLowerCase() === "false") {
                    defaultValue = false;
                }
    
                if (!csvHeader) {
                    newImplant[dtoProperty] = defaultValue;
                } else {
                    const value = rowDict[csvHeader];
                    newImplant[dtoProperty] = value;
                }
            });
    
            try {
                await dispatch(createOrUpdateImplant(newImplant.barcode, newImplant));
                
                if (implantCreateOrUpdateError) {
                    setImportLogs((prevLogs) => [
                        ...prevLogs,
                        {
                            barcode: newImplant.barcode,
                            error: implantCreateOrUpdateError,
                        },
                    ]);
    
                    dispatch({ type: IMPLANT_CREATE_RESET });
                }
            } catch (error) {
                setImportLogs((prevLogs) => [
                    ...prevLogs,
                    {
                        barcode: newImplant.barcode,
                        error: error,
                    },
                ]);
            }
    
            setCurrentRecord(rowIndex + 1);
            setCurrentProgress(Math.round(((rowIndex + 1) / totalRows) * 100));
        });
    
        try {
            await Promise.all(promises);
            setImporting(false);
        } catch (error) {
            importLogs.push({
                barcode: 'Error',
                error: error,
            });
            setImporting(false);
        }
    }

    const handleCsvExport = async () => {
        try {
            const currentDate = new Date().toISOString().slice(0, 10);
            const response = await axios.get('/api/v1/implant/export', {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
                responseType: 'blob',
            });

            const csvData = new Blob([response.data], { type: 'text/csv' });
            const csvUrl = URL.createObjectURL(csvData);
            const link = document.createElement('a');
            link.href = csvUrl;
            link.setAttribute('download', `implant_list_export_${currentDate}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error(error);
        }
    };
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <div className="welcome-box">
                                <div className="welcome-title d-flex justify-content-between align-items-center">
                                    <h2>Data Import/Export</h2>
                                </div>
                                <BackLink
                                    asElement="a"
                                    href="#"
                                    onClick={() => history.goBack()}
                                >
                                    Return back
                                </BackLink>
                            </div>


                        </Col>
                    </Row>



                    <Row>
                        <Col md="12">
                            <Card>
                                <Card.Content>
                                    <Card.Description>
                                        <h3>Import Devices</h3>
                                        <Checkboxes name="checkboxes">
                                            <Checkboxes.Box
                                                id="override"
                                                name="override"
                                                value="override"
                                                label="Override existing records based on barcode"
                                                checked={override}
                                                onChange={() => setOverride(!override)}
                                            >
                                                Override existing records based on barcode

                                            </Checkboxes.Box>
                                            <div className="nhsuk-u-padding-top-3"></div>
                                        </Checkboxes>

                                        <CSVReader
                                            onUploadAccepted={(results, file) => {
                                                const fileSizeInMB = file.size / (1024 * 1024);
                                                if (fileSizeInMB > 10) {
                                                    alert('File size exceeds the limit of 10 MB');
                                                    return;
                                                }
                                                // Process the uploaded file
                                                // get csv headers and generate mapping table using generateTable
                                                const csvHeaders = results.data[0];
                                                setCsvHeaders(csvHeaders);
                                                setCsvRows(results.data.slice(1));
                                                const table = generateMappingTable(newImplantDto, csvHeaders);
                                                setImplantMappingTable(table);

                                            }}
                                        >
                                            {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
                                                <>
                                                    <div style={styles.csvReader}>
                                                        <button type='button' {...getRootProps()} style={styles.browseFile}>
                                                            Browse file
                                                        </button>
                                                        <div style={styles.acceptedFile}>
                                                            {acceptedFile && `${acceptedFile.name} (${(acceptedFile.size / (1024 * 1024)).toFixed(2)} MB)`}
                                                        </div>
                                                        <button {...getRemoveFileProps()} style={styles.remove}>
                                                            Remove
                                                        </button>
                                                    </div>
                                                    <ProgressBar  {...ProgressBar} style={styles.progressBarBackgroundColor} />
                                                </>
                                            )}
                                        </CSVReader>


                                        {implantMappingTable}
                                    </Card.Description>
                                    <div className="d-flex justify-content-between">

                                        <Button
                                            onClick={() => {
                                                setStopImport(false);
                                                createImplantRecord(deviceMappings);
                                            }}
                                            disabled={importing}
                                        >
                                            Import
                                        </Button>
                                        {" "}
                                        <Button disabled={stopImport} secondary onClick={() => setStopImport(true)}>Stop Import</Button>
                                    </div>
                                    {implantError && (
                                        <ErrorSummary
                                            aria-labelledby="error-summary-title"
                                            role="alert"
                                            tabIndex={-1}
                                        >
                                            <ErrorSummary.Title className="nhsuk-u-margin-bottom-3">
                                                There is a problem
                                            </ErrorSummary.Title>
                                            <ErrorSummary.Body>
                                                <p>{implantError}</p>
                                            </ErrorSummary.Body>
                                        </ErrorSummary>
                                    )}


                                    <ProgressBar completed={currentProgress} />
                                    <p>Processed {currentRecord} of {totalRecords} records</p>
                                    <Details>
                                        <Details.Summary>View Logs</Details.Summary>
                                        <Details.Text>
                                            <Textarea
                                                name="failedRecords"
                                                rows={10}
                                                cols={50}
                                                readOnly
                                                value={importLogs.map(
                                                    (log) => `${log.barcode}: ${log.error}`
                                                ).join("\n")}
                                            />
                                        </Details.Text>
                                    </Details>

                                </Card.Content>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                        <Card>
                                <Card.Content>
                                    <Card.Description>
                                        <h3>Export Devices</h3>
                                        <p>Export devices to CSV. You can download up to 50K records</p>
                                        <Button
                                            onClick={handleCsvExport}
                                        >
                                            Export All Devices
                                        </Button>
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Col>
                    </Row>
                    



                </Container>
            </div>
        </React.Fragment>

    );
}






