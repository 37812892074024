import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FaAccessibleIcon, FaKey, FaList, FaQrcode, FaThList, FaUser } from "react-icons/fa";
import { Input, Button, WarningCallout, Select as SimpleSelect, Radios, BackLink, Card, Details, ErrorSummary, Fieldset, Label, ActionLink, Checkboxes, SummaryList } from "nhsuk-react-components";
import { getIdentityUserDetails, updateIdentityUser } from "../../actions/userActions";
import { getPatientEmergencyDetails, patientUpdateConsentIds, updatePatientConsentIds } from "../../actions/patientActions";
import { listOrganisations } from "../../actions/organisationActions";
import { listUsers } from "../../actions/userActions";
import { listEcgs } from "../../actions/ecgActions";
import { listSymptoms } from "../../actions/symptomActions";
import { listAetiologies } from "../../actions/aetiologyActions";
import { listFollowupCentres } from "../../actions/followupCentreActions";
import { listImplantCentres } from "../../actions/implantCentreActions";
import { listImplants } from "../../actions/implantActions";
import { listConsents } from "../../actions/consentActions";


import Checklist from "../../components/checklist/Checklist.component";
import ImplantInstanceListReadOnly from "../../components/implantInstanceList/ImplantInstanceListReadOnly.component";
import { debounce } from 'lodash';
// import modal from components
import Modal from "../../components/modal/Modal.component";
import { PATIENT_UPDATE_RESET } from "../../constants/patientConstants";
// import date functions
import { convertToComponentDate } from "../../utils/functions";



export default function PatientEmergencyAccessScreen({ history, match }) {

    // get patientId
    const patientId = match.params._id;


    const dispatch = useDispatch();

    useEffect(() => {
        document.title = "Emergency Access"; 
    }, []);


    // reset states
    useEffect(() => {
        dispatch({ type: PATIENT_UPDATE_RESET });
    }, [dispatch]);

    const [userId, setUserId] = useState();

    // Modal
    const [consentModalShow, setConsentModalShow] = useState(false);
    const [genericModalShow, setGenericModalShow] = useState(false);

    const openConsentModal = () => {
        setConsentModalShow(true);
    };

    const closeModal = () => {
        setConsentModalShow(false);
    };

    const openGenericModal = () => {
        setGenericModalShow(true);
    };

    const closeGenericModal = () => {
        setGenericModalShow(false);
    };

    const [consentModalContent, setConsentModalContent] = useState();
    const [genericModalContent, setGenericModalContent] = useState();

    const [targetDevice, setTargetDevice] = useState("android");

    const [newPatient, setNewPatient] = useState({});
    // get day month year
    const [dateofbirth, setdateofbirth] = useState(convertToComponentDate(Date.now()));
    const [generatorImplantDate, setgeneratorImplantDate] = useState(convertToComponentDate(Date.now()));

    // Consent properties to ignore
    // the following is a list of combined properties
    const consentPropertiesToIgnore = ["id", "patientConsents"];

    const genericPropertiesToIgnore = ["id", "patientEcgs", "patientSymptoms", "patientAetiologies", "patientImplants", "patientImplantCentres", "patientFollowUpCentres", "patientConsents", "patient"];

    // pass url
    const [passUrl, setPassUrl] = useState("");

    // selected ecgs
    const [selectedEcgs, setSelectedEcgs] = useState([]);
    const [selectedSymptoms, setSelectedSymptoms] = useState([]);
    const [selectedAetiologies, setSelectedAetiologies] = useState([]);
    const [selectedImplants, setSelectedImplants] = useState([]);
    const [selectedImplantCentres, setSelectedImplantCentres] = useState([]);
    const [selectedFollowupCentres, setSelectedFollowupCentres] = useState([]);
    const [selectedConsents, setSelectedConsents] = useState([]);

    // generic working state
    const [working, setWorking] = useState(false);

    // load patient emergency details (doesnt require authentication)
    const patientEmergencyDetails = useSelector((state) => state.patientEmergencyDetails);
    const { loading, error, patient } = patientEmergencyDetails;

    // update patient consentIds
    const patientUpdateConsentIds = useSelector((state) => state.patientUpdateConsentIds);
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate, patient: patientUpdated } = patientUpdateConsentIds;

    // load organisations
    const organisationList = useSelector((state) => state.organisationList);
    const { loading: loadingOrganisation, error: errorOrganisation, organisations } = organisationList;

    // load ecgs
    const ecgList = useSelector((state) => state.ecgList);
    const { loading: loadingEcg, error: errorEcg, ecgs } = ecgList;

    // load implants
    const implantList = useSelector((state) => state.implantList);
    const { loading: loadingImplant, error: errorImplant, implants } = implantList;

    // load symptoms
    const symptomList = useSelector((state) => state.symptomList);
    const { loading: loadingSymptom, error: errorSymptom, symptoms } = symptomList;

    // load aetiologies
    const aetiologyList = useSelector((state) => state.aetiologyList);
    const { loading: loadingAetiology, error: errorAetiology, aetiologies } = aetiologyList;

    // load followup centres
    const followupCentreList = useSelector((state) => state.followupCentreList);
    const { loading: loadingFollowupCentre, error: errorFollowupCentre, followupCentres } = followupCentreList;

    // load implant centres
    const implantCentreList = useSelector((state) => state.implantCentreList);
    const { loading: loadingImplantCentre, error: errorImplantCentre, implantCentres } = implantCentreList;

    // load consents
    const consentList = useSelector((state) => state.consentList);
    const { loading: loadingConsent, error: errorConsent, consents } = consentList;

    // update 
    const userList = useSelector((state) => state.userList);
    const { loading: loadingUsers, error: errorUsers, users } = userList;

    // handle user filter
    const [userListFilter, setuserListFilter] = useState({ query: "", page: 1, filter: "UserName" });


    // handle user filter change
    const handleUserFilterChange = (e) => {
        const selectedFilter = e.target.value;
        setuserListFilter({ ...userListFilter, filter: selectedFilter });
    };

    // debounce the search text field
    // Debounce the search function
    const debouncedSearch = debounce((text) => {
        setuserListFilter({ ...userListFilter, query: text });
    }, 1000);



    // get patient details
    useEffect(() => {

        // reset pass url
        dispatch(getPatientEmergencyDetails(patientId));
        dispatch(listOrganisations());
        dispatch(listUsers());
        dispatch(listEcgs());
        dispatch(listSymptoms());
        dispatch(listAetiologies());
        dispatch(listFollowupCentres());
        dispatch(listImplantCentres());
        dispatch(listImplants());
        dispatch(listConsents());
    }, [dispatch, patientId]);

    // listen to user filter changes
    useEffect(() => {
        dispatch(listUsers(userListFilter));
    }, [dispatch, userListFilter]);

    // listen to patient details changes
    useEffect(() => {
        if (patient && ecgs && symptoms && aetiologies && implants && implantCentres && followupCentres
            && patient.ecgIds && patient.symptomIds && patient.aetiologyIds && patient.implantIds
            && patient.implantCentreIds && patient.followUpCentreIds) {
            // update selected ecgs
            setSelectedEcgs(ecgs?.map(ecg => ({ value: ecg.id, label: ecg.name })).filter(ecg => patient.ecgIds?.includes(ecg.value)));
            // update selected symptoms
            setSelectedSymptoms(symptoms?.map(symptom => ({ value: symptom.id, label: symptom.name })).filter(symptom => patient.symptomIds?.includes(symptom.value)));
            // update selected aetiologies
            setSelectedAetiologies(aetiologies?.map(aetiology => ({ value: aetiology.id, label: aetiology.name })).filter(aetiology => patient.aetiologyIds?.includes(aetiology.value)));
            // update selected implants
            setSelectedImplants(implants?.map(implant => ({ value: implant.id, label: implant.barcode })).filter(implant => patient.implantIds?.includes(implant.value)));
            // update selected implant centres
            setSelectedImplantCentres(implantCentres?.map(implantCentre => ({ value: implantCentre.id, label: implantCentre.name })).filter(implantCentre => patient.implantCentreIds?.includes(implantCentre.value)));
            // update selected followup centres
            setSelectedFollowupCentres(followupCentres?.map(followupCentre => ({ value: followupCentre.id, label: followupCentre.name })).filter(followupCentre => patient.followUpCentreIds?.includes(followupCentre.value)));
            // set selected consents
            setSelectedConsents(consents?.map(consent => ({ value: consent.id, label: consent.consentType })).filter(consent => patient.consentIds?.includes(consent.value)));
            // update date of birth
            setdateofbirth(convertToComponentDate(patient?.dateOfBirth));
            // update generator implant date
            setgeneratorImplantDate(convertToComponentDate(patient?.generatorImplantDate));
            // update new patient
            setNewPatient(patient);

        }
    }, [patient, ecgs, symptoms, aetiologies, implants, implantCentres, followupCentres, consents]);



    // listen to selected ecgs
    useEffect(() => {
        if (selectedEcgs) {
            setNewPatient({ ...newPatient, ecgIds: selectedEcgs.map(ecg => ecg.value) });
        }
    }, [selectedEcgs]);

    // listen to selected symptoms
    useEffect(() => {
        if (selectedSymptoms) {
            setNewPatient({ ...newPatient, symptomIds: selectedSymptoms.map(symptom => symptom.value) });
        }
    }, [selectedSymptoms]);

    // listen to selected aetiologies
    useEffect(() => {
        if (selectedAetiologies) {
            setNewPatient({ ...newPatient, aetiologyIds: selectedAetiologies.map(aetiology => aetiology.value) });
        }
    }, [selectedAetiologies]);

    // listen to selected implants
    useEffect(() => {
        if (selectedImplants) {
            setNewPatient({ ...newPatient, implantIds: selectedImplants.map(implant => implant.value) });
        }
    }, [selectedImplants]);

    // listen to selected implant centres
    useEffect(() => {
        if (selectedImplantCentres) {
            setNewPatient({ ...newPatient, implantCentreIds: selectedImplantCentres.map(implantCentre => implantCentre.value) });
        }
    }, [selectedImplantCentres]);

    // listen to selected followup centres
    useEffect(() => {
        if (selectedFollowupCentres) {
            setNewPatient({ ...newPatient, followUpCentreIds: selectedFollowupCentres.map(followupCentre => followupCentre.value) });
        }
    }, [selectedFollowupCentres]);

    // listen to selected consents
    useEffect(() => {
        if (selectedConsents) {
            setNewPatient({ ...newPatient, consentIds: selectedConsents.map(consent => consent.value) });
        }
    }, [selectedConsents]);


    // handle click on the checkboxes 
    // get the consent JSON and display it in a modal
    const handleConsentClick = (consentId) => {
        const consent = consents.find(consent => consent.id === consentId);
        setConsentModalContent(consent);
        openConsentModal();
    };

    // handle display entity details in modal
    const handleEntityClick = (entity) => {
        if (!entity) return;
        setGenericModalContent(entity);
        openGenericModal();
    };

    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>
                    <h1>Emergency Access</h1>

                    <WarningCallout label="Warning callout">
                        <WarningCallout.Label>Important Note</WarningCallout.Label>
                        <p>Unauthorized access to emergency patient data is strictly prohibited. Clinicians, ensure that your code usage is solely for emergency purposes. Any misuse or unauthorized access will result in severe consequences. Prioritize patient privacy and adhere to ethical coding practices.</p>
                    </WarningCallout>

                    <div className="nhsuk-grid-row">

                        <div className="welcome-title d-flex justify-content-between align-items-center">


                        </div>
                        <div className="nhsuk-grid-column-one-half">
                            <div className="welcome-box">

                                <div className="welcome-content">
                                    <div className="welcome-text">

                                        <Card>
                                            {newPatient?.identityUserId ? (

                                                <Card.Content>
                                                    <Card.Heading className="nhsuk-heading-m">
                                                        <div className="icon-container ">
                                                            <FaUser size={30} />
                                                        </div>
                                                        {newPatient?.forename} {newPatient?.surname}
                                                    </Card.Heading>


                                                    <SummaryList>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>
                                                                Identity User
                                                            </SummaryList.Key>
                                                            <SummaryList.Value>
                                                                {newPatient?.identityUserId}
                                                            </SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>
                                                                NHS Number
                                                            </SummaryList.Key>
                                                            <SummaryList.Value>
                                                                {newPatient?.nhsNumber}
                                                            </SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>
                                                                Date of Birth
                                                            </SummaryList.Key>
                                                            <SummaryList.Value>
                                                                {dateofbirth?.day}/{dateofbirth?.month}/{dateofbirth?.year}
                                                            </SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>
                                                                Email
                                                            </SummaryList.Key>
                                                            <SummaryList.Value>
                                                                {newPatient?.email}
                                                            </SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>
                                                                Phone Number
                                                            </SummaryList.Key>
                                                            <SummaryList.Value>
                                                                {newPatient?.phoneNumber}
                                                            </SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>
                                                                Abandoned Hardware?
                                                            </SummaryList.Key>
                                                            <SummaryList.Value>
                                                                {newPatient?.abondonedHardware ? "Yes" : "No"}
                                                            </SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>
                                                                MR Conditional?
                                                            </SummaryList.Key>
                                                            <SummaryList.Value>
                                                                {newPatient?.mrConditional ? "Yes" : "No"}
                                                            </SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>
                                                                Implant Centres
                                                            </SummaryList.Key>
                                                            <SummaryList.Value>
                                                                <ul>
                                                                    {
                                                                        newPatient?.implantCentreIds?.map(implantCentreId => (
                                                                            <li>

                                                                                <Link
                                                                                    to="#"
                                                                                    onClick={() => {
                                                                                        const selectedImplantCentre = implantCentres?.find(implantCentre => implantCentre.id === implantCentreId);
                                                                                        handleEntityClick(selectedImplantCentre);
                                                                                    }}
                                                                                >
                                                                                    {implantCentres?.find(implantCentre => implantCentre.id === implantCentreId)?.name}
                                                                                </Link>
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>

                                                            </SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>
                                                                Followup Centres
                                                            </SummaryList.Key>
                                                            <SummaryList.Value>
                                                                <ul>
                                                                    {
                                                                        newPatient?.followUpCentreIds?.map(followUpCentreId => (
                                                                            <li>

                                                                                <Link
                                                                                    to="#"
                                                                                    onClick={() => {
                                                                                        const selectedFollowupCentre = followupCentres?.find(followupCentre => followupCentre.id === followUpCentreId);
                                                                                        handleEntityClick(selectedFollowupCentre);
                                                                                    }}
                                                                                >
                                                                                    {followupCentres?.find(followupCentre => followupCentre.id === followUpCentreId)?.name}
                                                                                </Link>

                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>

                                                            </SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>
                                                                Symptoms
                                                            </SummaryList.Key>
                                                            <SummaryList.Value>
                                                                <ul>
                                                                    {
                                                                        newPatient?.symptomIds?.map(symptomId => (
                                                                            <li>

                                                                                <Link
                                                                                    to="#"
                                                                                    onClick={() => {
                                                                                        const selectedSymptom = symptoms?.find(symptom => symptom.id === symptomId);
                                                                                        handleEntityClick(selectedSymptom);
                                                                                    }}
                                                                                >
                                                                                    {symptoms?.find(symptom => symptom.id === symptomId)?.name}
                                                                                </Link>

                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>

                                                            </SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>
                                                                Aetiologies
                                                            </SummaryList.Key>
                                                            <SummaryList.Value>
                                                                <ul>
                                                                    {
                                                                        newPatient?.aetiologyIds?.map(aetiologyId => (
                                                                            <li>

                                                                                <Link
                                                                                    to="#"
                                                                                    onClick={() => {
                                                                                        const selectedAetiology = aetiologies?.find(aetiology => aetiology.id === aetiologyId);
                                                                                        handleEntityClick(selectedAetiology);
                                                                                    }}
                                                                                >
                                                                                    {aetiologies?.find(aetiology => aetiology.id === aetiologyId)?.name}
                                                                                </Link>

                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>

                                                            </SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>
                                                                ECGs
                                                            </SummaryList.Key>
                                                            <SummaryList.Value>
                                                                <ul>
                                                                    {
                                                                        newPatient?.ecgIds?.map(ecgId => (
                                                                            <li>

                                                                                <Link
                                                                                    to="#"
                                                                                    onClick={() => {
                                                                                        const selectedEcg = ecgs?.find(ecg => ecg.id === ecgId);
                                                                                        handleEntityClick(selectedEcg);
                                                                                    }}
                                                                                >
                                                                                    {ecgs?.find(ecg => ecg.id === ecgId)?.name}
                                                                                </Link>


                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>

                                                            </SummaryList.Value>
                                                        </SummaryList.Row>

                                                    </SummaryList>

                                                </Card.Content>
                                            ) : (
                                                (loading && !patient) ?
                                                    <Card.Content>
                                                        <Card.Heading className="nhsuk-heading-m">
                                                            Fetching patient details...
                                                        </Card.Heading>
                                                    </Card.Content>
                                                    : <Card.Content>
                                                        <Card.Heading className="nhsuk-heading-m">
                                                            <div className="icon-container ">
                                                                <FaUser size={30} />
                                                            </div>
                                                            {newPatient?.forename} {newPatient?.surname}
                                                        </Card.Heading>
                                                        <Card.Description>
                                                            Please contact an administrator to have your account set up accordingly.
                                                        </Card.Description>
                                                    </Card.Content>
                                            )}


                                        </Card>

                                        <Modal show={genericModalShow} handleClose={closeGenericModal}>
                                            {genericModalContent && (
                                                <SummaryList>
                                                    {
                                                        Object.entries(genericModalContent).map(([key, value]) => (
                                                            !genericPropertiesToIgnore.includes(key) &&
                                                            <SummaryList.Row>
                                                                <SummaryList.Key>
                                                                    {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                                                                </SummaryList.Key>
                                                                <SummaryList.Value>
                                                                    {value ? value.toString() : "-"}
                                                                </SummaryList.Value>
                                                            </SummaryList.Row>
                                                        ))
                                                    }

                                                </SummaryList>

                                            )}
                                            <Button
                                                type="submit"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    closeGenericModal();
                                                }
                                                }
                                            >   Close
                                            </Button>

                                        </Modal>



                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nhsuk-grid-column-one-half" style={{ display: 'flex', justifyContent: 'center' }}>

                            <Card style={{ padding: "30px" }}>
                                <Card.Heading className="nhsuk-heading-m">
                                    Implants
                                </Card.Heading>

                                <Card.Description>
                                    <ImplantInstanceListReadOnly
                                        patientNHSNumber={newPatient?.nhsNumber}
                                    />

                                    <div className="nhsuk-u-padding-top-3"></div>
                                    <h3>Patient consents</h3>

                                    {loadingConsent ? <p>Loading consents...</p> : errorConsent ? <p>{errorConsent}</p> :

                                        <Checklist name="checklist" hint="Consent information." heading={"This patient allows:"} expand={true}>

                                            {
                                                selectedConsents?.map(selectedContent => (
                                                    <Checklist.Item
                                                        mode={"success"}
                                                        id={selectedContent.id}
                                                        name={consents?.find(consent => consent.id === selectedContent.value)?.consentType}
                                                    >
                                                        {consents?.find(consent => consent.id === selectedContent.value)?.consentType}
                                                    </Checklist.Item>

                                                ))
                                            }

                                        </Checklist>
                                    }

                                </Card.Description>


                            </Card>
                        </div>

                    </div>

                </Container>
            </div>
        </React.Fragment>
    );
}




