import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Table, ActionLink, Label, Tag, Input, Fieldset, Radios, BackLink, Card, Details, Button, DateInput, InsetText, Textarea, ErrorSummary, Select, Checkboxes } from 'nhsuk-react-components';
import { listConsents, createConsent, updateConsent, deleteConsent, getConsentDetails } from '../../actions/consentActions';
// constants 
import { CONSENT_CREATE_RESET, CONSENT_UPDATE_RESET } from '../../constants/consentConstants';
import Pagination from '../../components/pagination/Pagination.component';
import AppSpinner from '../../components/spinner/AppSpinner.component';

import Callout from '../../components/callout/Callout.component';

import {convertToISODate, convertToComponentDate} from '../../utils/functions';

// debouncer 
import { debounce } from 'lodash';

export default function ConsentCRUDScreen({ history }) {

    // use react hook Dispatch to dispatch actions
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = "Edit Consents"; 
    }, []);


    // reset the state of the consentCreate and consentUpdate
    useEffect(() => {
        dispatch({ type: CONSENT_CREATE_RESET });
        dispatch({ type: CONSENT_UPDATE_RESET });
    }, [dispatch]);

    // set initial filter to empty string, set default value 
    // {{ _.docker_api_url }}/api/v1/consent/all?page=1&limit=2&filter=Email&query=gmail
    const [consentListFilter, setconsentListFilter] = useState({ query: '', page: 1, filter: 'Name' });

    // get consent list from redux store
    const consentList = useSelector((state) => state.consentList);
    const { loading, error, consents, currentPage, totalPages } = consentList;

    // get consent create from redux store
    const consentCreate = useSelector((state) => state.consentCreate);
    const { success: successCreate, error: consentCreateErrors } = consentCreate;

    // get consent update from redux store
    const consentUpdate = useSelector((state) => state.consentUpdate);
    const { success: successUpdate, loading: consentUpdateProcessing } = consentUpdate;

    // get consent delete from redux store
    const consentDelete = useSelector((state) => state.consentDelete);
    const { success: successDelete } = consentDelete;

    // get consent details from redux store
    const consentDetails = useSelector((state) => state.consentDetails);
    const { consent: consentDet, loading: consentDetailsLoading } = consentDetails;

    // newConsent state
    const [newConsent, setNewConsent] = useState({});
    const [updatedConsent, setUpdatedConsent] = useState({});
    const [editMode, setEditMode] = useState(false);

    const consentTypes = [
        "Full Access",
        "Limited Access",
        "Emergency Access",
        "Research Access",
        "Treatment Access",
        "Mental Health Access",
        "Donation Access",
    ];
    


    // listen to filter changes
    useEffect(() => {
        dispatch(listConsents(consentListFilter));
    }, [dispatch, consentListFilter]);

    // handle page change
    const handlePageChange = (newPage) => {
        setconsentListFilter({ ...consentListFilter, page: newPage });
    };

    // handle search text field change
    const handleSearchChange = (e) => {
        setconsentListFilter({ ...consentListFilter, query: e.target.value });
    };

    // handle filter value change
    const handleFilterChange = (e) => {
        const selectedFilter = e.target.value;
        setconsentListFilter({ ...consentListFilter, filter: selectedFilter });
    };

    // get all consents
    useEffect(() => {
        dispatch(listConsents({}));
    }, [dispatch]);

    // listen to consentDet 
    useEffect(() => {
        if (consentDet) {
            // use spread operator to copy the consentDet object
            setUpdatedConsent({ ...consentDet });
        }
    }, [consentDet]);

    // listen to editMode
    useEffect(() => {
        if (editMode === false) {
            // empty the newConsent state
            setNewConsent({
                consentType: '',
                // datetime now in ISO format
                consentDate: (new Date().toISOString()),
                consentDocument: '',
                consentPurpose: '',
                consentScope: '',
                consentAdditionalInfo: '',
            });
        }
    }, [editMode]);

    // handle delete
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this consent?')) {
            dispatch(deleteConsent(id)).then(() => {
                dispatch(listConsents({}));
            }
            );
        }
    };

    // handle edit
    const handleEditConsent = (id) => {
        
        dispatch(getConsentDetails(id));
        setEditMode(true);
    };

    // handle create
    const handleCreateConsent = () => {
        dispatch(createConsent(newConsent)).then(() => {
            setUpdatedConsent(newConsent);
            setEditMode(true);
            dispatch(listConsents({}));
        }
        );
    };

    // handle update
    const handleUpdateConsent = () => {
        dispatch(updateConsent(updatedConsent.id, updatedConsent)).then(() => {

            setUpdatedConsent(updatedConsent);
            setEditMode(true);
            dispatch(getConsentDetails(updatedConsent.id));
        }
        );
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <div className="welcome-box">
                                <div className="welcome-title d-flex justify-content-between align-items-center">
                                    <h2>Consent Management</h2>
                                </div>
                                <BackLink
                                    asElement="a"
                                    href="#"
                                    onClick={() => history.goBack()}
                                >
                                    Return back
                                </BackLink>
                                <InsetText>
                                    <p>
                                        This section allows you to manage and configure the consents. You can create, update and delete consents in one place!
                                    </p>
                                </InsetText>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">

                            {consentCreateErrors ?

                                <ErrorSummary
                                    aria-labelledby="error-summary-title"
                                    role="alert"
                                    tabIndex="-1"
                                >
                                    <ErrorSummary.Title id="error-summary-title">
                                        There is a problem
                                    </ErrorSummary.Title>
                                    <ErrorSummary.Body>
                                        <p>Check the following:</p>
                                        {consentCreateErrors ?
                                            <ul className="nhsuk-list nhsuk-list--bullet">
                                                {consentCreateErrors.map((error) => (
                                                    <li key={error}>{error}</li>
                                                ))}
                                            </ul>
                                            : null

                                        }

                                    </ErrorSummary.Body>
                                </ErrorSummary>
                                : null}
                        </Col>
                    </Row>


                    <div className="nhsuk-grid-column-one-half">
                        <Card>
                            <Card.Content>
                                <Card.Heading className="nhsuk-heading-m">
                                    <Input
                                        id="search-consents"
                                        label="Search consents by type"
                                        name="search-consents"
                                        type="search"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSearchChange(e);
                                            }
                                        }
                                        }
                                    />
                                </Card.Heading>
                                <Card.Description>
                                    <Fieldset>
                                        {consents && consents.length > 0 ? (
                                            consents.map((consent) => (
                                                <Details key={consent.id}>
                                                    <Details.Summary>
                                                        {consent.consentType}
                                                    </Details.Summary>
                                                    <Details.Text>
                                                        <p>
                                                            <strong>Last Updated: </strong>
                                                                {(consent.consentDate).split('T')[0]}
                                                        </p>

                                                        <div className="d-flex justify-content-between">
                                                            <Button
                                                                disabled={consentDetailsLoading}
                                                                onClick={() =>
                                                                    handleEditConsent(consent.id)
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                            {" "}
                                                            <Button
                                                                secondary

                                                                onClick={() => handleDelete(consent.id)}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </Details.Text>
                                                </Details>
                                            ))
                                        ) : (
                                            null
                                        )}

                                    </Fieldset>
                                    {loading ? (
                                        <AppSpinner />
                                    ) : error ? (
                                        <p>{error}</p>
                                    ) : (
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            handlePageChange={handlePageChange}
                                        />

                                    )}

                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </div>
                    <div className="nhsuk-grid-column-one-half">
                        {editMode ? (
                            <Card id='edit-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Edit Consent
                                        <InsetText>
                                            <p>
                                                This section allows you to edit an consent. If you want to create a new consent, please click the "Create Consent" button below.
                                            </p>
                                            <Button
                                                onClick={() => {
                                                    setEditMode(false);
                                                }}
                                            >
                                                + Create Consent
                                            </Button>

                                        </InsetText>

                                    </Card.Heading>
                                    <Card.Description>
                                        <Select
                                            id="consentType"
                                            label="Consent Type"
                                            name="consentType"
                                            onChange={(e) => setUpdatedConsent({ ...updatedConsent, consentType: e.target.value })}
                                            value={updatedConsent?.consentType}
                                            required
                                        >
                                            <option value="">Select consent type</option>
                                            {consentTypes.map((consentType) => (
                                                <option key={consentType} value={consentType}>
                                                    {consentType}   
                                                </option>
                                            ))}
                                        </Select>

                                        <DateInput
                                            id="consentDate"
                                            label="Last Updated"
                                            name="consentDate"
                                            type="date"
                                            onChange={(e) => setUpdatedConsent({ ...updatedConsent, consentDate: convertToISODate(e.target.value) })}
                                            value={convertToComponentDate(updatedConsent?.consentDate)}
                                            required
                                        />

                                        <Textarea
                                            id="consentDocument"
                                            label="Consent Description"
                                            name="consentDocument"
                                            onChange={(e) => setUpdatedConsent({ ...updatedConsent, consentDocument: e.target.value })}
                                            value={updatedConsent?.consentDocument}
                                            style={{ height: '100px' }}
                                        />

                                        <Textarea
                                            id="consentPurpose"
                                            label="Consent Purpose"
                                            name="consentPurpose"
                                            onChange={(e) => setUpdatedConsent({ ...updatedConsent, consentPurpose: e.target.value })}
                                            value={updatedConsent?.consentPurpose}
                                            style={{ height: '100px' }}
                                        />

                                        <Textarea
                                            id="consentScope"
                                            label="Consent Scope"
                                            name="consentScope"
                                            onChange={(e) => setUpdatedConsent({ ...updatedConsent, consentScope: e.target.value })}
                                            value={updatedConsent?.consentScope}
                                            style={{ height: '100px' }}
                                        />

                                        <Textarea
                                            id="consentAddionalInfo"
                                            label="Consent Additional Info"
                                            name="consentAddionalInfo"
                                            onChange={(e) => setUpdatedConsent({ ...updatedConsent, consentAdditionalInfo: e.target.value })}
                                            value={updatedConsent?.consentAdditionalInfo}
                                            style={{ height: '100px' }}
                                        />


                                        {(successCreate || successUpdate) ?
                                            <Callout
                                                label="Success"
                                                header="Success!"
                                                autoDismiss={true}
                                                dismissTime={5000}
                                                time={new Date().toLocaleTimeString()}
                                                title="Success!"
                                                message={successCreate ? "The consent was created successfully." : "The consent was updated successfully."}
                                                mode="success"
                                            />
                                            : null
                                        }

                                    </Card.Description>
                                    <Button
                                        disabled={consentUpdateProcessing}
                                        onClick={handleUpdateConsent}
                                    >
                                        Save changes
                                    </Button>

                                </Card.Content>
                            </Card>
                        ) : (
                            <Card id='create-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Create a new Consent
                                    </Card.Heading>
                                    <Card.Description>
                                        <Select
                                            id="consentType"
                                            label="Consent Type"
                                            name="consentType"
                                            onChange={(e) => setNewConsent({ ...newConsent, consentType: e.target.value })}
                                            value={newConsent?.consentType}
                                            required
                                        >
                                            <option value="">Select consent type</option>
                                            {consentTypes.map((consentType) => (
                                                <option key={consentType} value={consentType}>
                                                    {consentType}   
                                                </option>
                                            ))}
                                        </Select>

                                        <DateInput
                                            id="consentDate"
                                            label="Last Updated"
                                            name="consentDate"
                                            type="date"
                                            onChange={(e) => setNewConsent({ ...newConsent, consentDate: convertToISODate(e.target.value) })}
                                            value={convertToComponentDate(newConsent?.consentDate)}
                                            required
                                        />

                                        <Textarea
                                            id="consentDocument"
                                            label="Consent Document"
                                            name="consentDocument"
                                            onChange={(e) => setNewConsent({ ...newConsent, consentDocument: e.target.value })}
                                            value={newConsent?.consentDocument}
                                            style={{ height: '100px' }}
                                        />

                                        <Textarea
                                            id="consentPurpose"
                                            label="Consent Purpose"
                                            name="consentPurpose"
                                            onChange={(e) => setNewConsent({ ...newConsent, consentPurpose: e.target.value })}
                                            value={newConsent?.consentPurpose}
                                            style={{ height: '100px' }}

                                        />

                                        <Textarea
                                            id="consentScope"
                                            label="Consent Scope"
                                            name="consentScope"
                                            onChange={(e) => setNewConsent({ ...newConsent, consentScope: e.target.value })}
                                            value={newConsent?.consentScope}
                                            style={{ height: '100px' }}
                                        />

                                        <Textarea
                                            id="consentAddionalInfo"
                                            label="Consent Additional Info"
                                            name="consentAddionalInfo"
                                            onChange={(e) => setNewConsent({ ...newConsent, consentAdditionalInfo: e.target.value })}
                                            value={newConsent?.consentAdditionalInfo}
                                            style={{ height: '100px' }}
                                        />

                                        {(successCreate || successUpdate) ?
                                            <Callout
                                                label="Success"
                                                header="Success!"
                                                autoDismiss={true}
                                                dismissTime={5000}
                                                time={new Date().toLocaleTimeString()}
                                                title="Success!"
                                                message={successCreate ? "The consent was created successfully." : "The consent was updated successfully."}
                                                mode="success"
                                            />
                                            : null
                                        }
                                         

                                    </Card.Description>

                                    <Button
                                        disabled={consentUpdateProcessing}
                                        onClick={handleCreateConsent}
                                    >
                                        Create Consent
                                    </Button>

                                </Card.Content>
                            </Card>
                        )}

                    </div>

                </Container>
            </div>
        </React.Fragment>

    );
}






