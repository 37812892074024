export const SYMPTOM_LIST_REQUEST = 'SYMPTOM_LIST_REQUEST'
export const SYMPTOM_LIST_SUCCESS = 'SYMPTOM_LIST_SUCCESS'
export const SYMPTOM_LIST_FAIL = 'SYMPTOM_LIST_FAIL'


export const SYMPTOM_DETAILS_REQUEST = 'SYMPTOM_DETAILS_REQUEST'
export const SYMPTOM_DETAILS_SUCCESS = 'SYMPTOM_DETAILS_SUCCESS'
export const SYMPTOM_DETAILS_FAIL = 'SYMPTOM_DETAILS_FAIL'


export const SYMPTOM_DELETE_REQUEST = 'SYMPTOM_DELETE_REQUEST'
export const SYMPTOM_DELETE_SUCCESS = 'SYMPTOM_DELETE_SUCCESS'
export const SYMPTOM_DELETE_FAIL = 'SYMPTOM_DELETE_FAIL'

export const SYMPTOM_CREATE_REQUEST = 'SYMPTOM_CREATE_REQUEST'
export const SYMPTOM_CREATE_SUCCESS = 'SYMPTOM_CREATE_SUCCESS'
export const SYMPTOM_CREATE_FAIL = 'SYMPTOM_CREATE_FAIL'
export const SYMPTOM_CREATE_RESET = 'SYMPTOM_CREATE_RESET'


export const SYMPTOM_UPDATE_REQUEST = 'SYMPTOM_UPDATE_REQUEST'
export const SYMPTOM_UPDATE_SUCCESS = 'SYMPTOM_UPDATE_SUCCESS'
export const SYMPTOM_UPDATE_FAIL = 'SYMPTOM_UPDATE_FAIL'
export const SYMPTOM_UPDATE_RESET = 'SYMPTOM_UPDATE_RESET'

export const SYMPTOM_REQUEST = 'SYMPTOM_REQUEST'
export const SYMPTOM_SUCCESS = 'SYMPTOM_SUCCESS'
export const SYMPTOM_FAIL = 'SYMPTOM_FAIL'
export const SYMPTOM_RESET = 'SYMPTOM_RESET'

export const SYMPTOM_COUNT_REQUEST = "SYMPTOM_COUNT_REQUEST";
export const SYMPTOM_COUNT_SUCCESS = "SYMPTOM_COUNT_SUCCESS";
export const SYMPTOM_COUNT_FAIL = "SYMPTOM_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'