import React from 'react';

const Pagination = ({ currentPage, totalPages, handlePageChange }) => {
  const pages = [];
  const visiblePages = 5; // Set the number of visible page numbers
  const ellipsis = '...'; // Define the ellipsis symbol

  // Calculate the range of visible page numbers
  let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
  let endPage = Math.min(totalPages, startPage + visiblePages - 1);

  // Adjust the startPage if the endPage is at the maximum limit
  if (endPage === totalPages) {
    startPage = Math.max(1, endPage - visiblePages + 1);
  }

  // Add page numbers to the array
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return (
    <nav aria-label="Page navigation" style={{ padding: '20px' }}>
      <div className="pagination justify-content-center">
        {pages.length > 1 && (
          <span className="pagination__page">
            Pages:
          </span>
        )}

        {startPage > 1 && (
          <a
            className="btn btn-outline-primary"
            style={{ cursor: 'pointer', margin: '5px', borderRadius: '20px' }}
            onClick={() => handlePageChange(1)}
          >
            1
          </a>
        )}

        {startPage > 2 && (
          <span className="pagination__ellipsis">{ellipsis}</span>
        )}

        {pages.map((page) => (
          <a
            key={page}
            className={`btn btn-outline-primary ${currentPage === page ? 'active' : ''}`}
            style={{ cursor: 'pointer', margin: '5px', borderRadius: '20px' }}
            onClick={() => handlePageChange(page)}
            aria-current={currentPage === page ? 'page' : undefined}
          >
            {page}
          </a>
        ))}

        {endPage < totalPages - 1 && (
          <span className="pagination__ellipsis">{ellipsis}</span>
        )}

        {endPage < totalPages && (
          <a
            className="btn btn-outline-primary"
            style={{ cursor: 'pointer', margin: '5px', borderRadius: '20px' }}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </a>
        )}
      </div>
    </nav>
  );
};

export default Pagination;
