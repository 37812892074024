import axios from 'axios';

import {
    IMPLANT_CENTRE_LIST_REQUEST,
    IMPLANT_CENTRE_LIST_SUCCESS,
    IMPLANT_CENTRE_LIST_FAIL,

    IMPLANT_CENTRE_DETAILS_REQUEST,
    IMPLANT_CENTRE_DETAILS_SUCCESS,
    IMPLANT_CENTRE_DETAILS_FAIL,

    IMPLANT_CENTRE_DELETE_REQUEST,
    IMPLANT_CENTRE_DELETE_SUCCESS,
    IMPLANT_CENTRE_DELETE_FAIL,

    IMPLANT_CENTRE_CREATE_REQUEST,
    IMPLANT_CENTRE_CREATE_SUCCESS,
    IMPLANT_CENTRE_CREATE_FAIL,

    IMPLANT_CENTRE_UPDATE_REQUEST,
    IMPLANT_CENTRE_UPDATE_SUCCESS,
    IMPLANT_CENTRE_UPDATE_FAIL,

    IMPLANT_CENTRE_REQUEST,
    IMPLANT_CENTRE_FAIL,
    IMPLANT_CENTRE_RESET,
    IMPLANT_CENTRE_SUCCESS,

    IMPLANT_CENTRE_COUNT_SUCCESS,
    IMPLANT_CENTRE_COUNT_REQUEST,
    IMPLANT_CENTRE_COUNT_FAIL,

    SET_CURRENT_PAGE,
    

} from "../constants/implantCentreConstants";

// use dispatch to dispatch the request and throw it into the reducer
export const listImplantCentres = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: IMPLANT_CENTRE_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        // get the filter
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
            },
            params
        }
        const { data } = await axios.get('/api/v1/implantCentre/all', config);

        dispatch({
            type: IMPLANT_CENTRE_LIST_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: IMPLANT_CENTRE_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later implantCentres",
        });
    }
}

// count implantCentres
export const countImplantCentres = () => async (dispatch, getState) => {
    try {
      dispatch({ type: IMPLANT_CENTRE_COUNT_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      }
      const { data } = await axios.get('/api/v1/implantCentre/count', config);
  
      dispatch({
        type: IMPLANT_CENTRE_COUNT_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: IMPLANT_CENTRE_COUNT_FAIL,
        payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : "Failed to fetch implantCentre counts",
      });
    }
  }
  


export const getImplantCentreDetails = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: IMPLANT_CENTRE_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/api/v1/implantCentre/${_id}`, config);

        dispatch({
            type: IMPLANT_CENTRE_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: IMPLANT_CENTRE_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const deleteImplantCentre = (_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: IMPLANT_CENTRE_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/v1/implantCentre/${_id}/`,
            config
        )

        dispatch({
            type: IMPLANT_CENTRE_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: IMPLANT_CENTRE_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createImplantCentre = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: IMPLANT_CENTRE_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const data = await axios.post(
            `/api/v1/implantCentre/`,
            changes,
            config
        )

        dispatch({
            type: IMPLANT_CENTRE_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: IMPLANT_CENTRE_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
const loadImplantCentreBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
    });
};

// make implantCentre using a local implantCentre
export const makeImplantCentre = (file, implantCentreId, apiEndPoint, apiKey) => async (dispatch, getState) => {

    const implantCentre = await loadImplantCentreBase64(file);
    const implantCentre_input = JSON.stringify({ "implantCentre": implantCentre });
    const {
        userLogin: { userInfo },
    } = getState()

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }

    const data = await axios.post(
        apiEndPoint,
        implantCentre_input,
        config
    )
    try {
        dispatch({
            type: IMPLANT_CENTRE_RESET
        })
        await axios({
            method: "POST",
            url: apiEndPoint,

            data: JSON.stringify({ "data": implantCentre }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
                dispatch({
                    type: IMPLANT_CENTRE_SUCCESS
                });
                if (implantCentreId && response.data) {
                    dispatch(updateImplantCentre(implantCentreId, { implantCentre: response.data }));
                }
            })
            .catch(function (error) {
                dispatch({
                    type: IMPLANT_CENTRE_FAIL
                });
                console.log(error.message);
            });
    }

    catch (error) {
        dispatch({
            type: IMPLANT_CENTRE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}


export const updateImplantCentre = (_id, changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: IMPLANT_CENTRE_UPDATE_REQUEST
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const response = await axios.patch(
            `/api/v1/implantCentre/${_id}/`,
            changes,
            config
        );

        dispatch({
            type: IMPLANT_CENTRE_UPDATE_SUCCESS,
            payload: response.data, // Assuming the updated implantCentre data is returned
        });

        dispatch({
            type: IMPLANT_CENTRE_DETAILS_SUCCESS,
            payload: response.data, // Assuming the updated implantCentre data is returned
        });
    } catch (error) {
        let errorPayload = [];

        if (error.response && error.response.status === 400 && error.response.data.errors) {
            const validationErrors = error.response.data.errors;
            // Concatenate detailed validation errors to the error payload array
            errorPayload = Object.values(validationErrors).flat();
        }

        // Include generic error message or additional error data in the payload array
        if (error.response && error.response.data.detail) {
            errorPayload.push(error.response.data.detail);
        } else {
            errorPayload.push(error.message);
        }

        dispatch({
            type: IMPLANT_CENTRE_UPDATE_FAIL,
            payload: errorPayload,
        });
    }
};
