import axios from 'axios';

import {
    ECG_LIST_REQUEST,
    ECG_LIST_SUCCESS,
    ECG_LIST_FAIL,

    ECG_DETAILS_REQUEST,
    ECG_DETAILS_SUCCESS,
    ECG_DETAILS_FAIL,

    ECG_DELETE_REQUEST,
    ECG_DELETE_SUCCESS,
    ECG_DELETE_FAIL,

    ECG_CREATE_REQUEST,
    ECG_CREATE_SUCCESS,
    ECG_CREATE_FAIL,

    ECG_UPDATE_REQUEST,
    ECG_UPDATE_SUCCESS,
    ECG_UPDATE_FAIL,

    ECG_REQUEST,
    ECG_FAIL,
    ECG_RESET,
    ECG_SUCCESS,

    ECG_COUNT_SUCCESS,
    ECG_COUNT_REQUEST,
    ECG_COUNT_FAIL,

    SET_CURRENT_PAGE,
    

} from "../constants/ecgConstants";

// use dispatch to dispatch the request and throw it into the reducer
export const listEcgs = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: ECG_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        // get the filter
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
            },
            params
        }
        const { data } = await axios.get('/api/v1/ecg/all', config);

        dispatch({
            type: ECG_LIST_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: ECG_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later ecgs",
        });
    }
}

// count ecgs
export const countEcgs = () => async (dispatch, getState) => {
    try {
      dispatch({ type: ECG_COUNT_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`
        },
      }
      const { data } = await axios.get('/api/v1/ecg/count', config);
  
      dispatch({
        type: ECG_COUNT_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: ECG_COUNT_FAIL,
        payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : "Failed to fetch ecg counts",
      });
    }
  }
  


export const getEcgDetails = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: ECG_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
            }
        }
        const { data } = await axios.get(`/api/v1/ecg/${_id}`, config);

        dispatch({
            type: ECG_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: ECG_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const deleteEcg = (_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ECG_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/v1/ecg/${_id}/`,
            config
        )

        dispatch({
            type: ECG_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: ECG_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createEcg = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ECG_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const data = await axios.post(
            `/api/v1/ecg/`,
            changes,
            config
        )

        dispatch({
            type: ECG_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: ECG_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
const loadEcgBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
    });
};

// make ecg using a local ecg
export const makeEcg = (file, ecgId, apiEndPoint, apiKey) => async (dispatch, getState) => {

    const ecg = await loadEcgBase64(file);
    const ecg_input = JSON.stringify({ "ecg": ecg });
    const {
        userLogin: { userInfo },
    } = getState()

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }

    const data = await axios.post(
        apiEndPoint,
        ecg_input,
        config
    )
    try {
        dispatch({
            type: ECG_RESET
        })
        await axios({
            method: "POST",
            url: apiEndPoint,

            data: JSON.stringify({ "data": ecg }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
                dispatch({
                    type: ECG_SUCCESS
                });
                if (ecgId && response.data) {
                    dispatch(updateEcg(ecgId, { ecg: response.data }));
                }
            })
            .catch(function (error) {
                dispatch({
                    type: ECG_FAIL
                });
                console.log(error.message);
            });
    }

    catch (error) {
        dispatch({
            type: ECG_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}


export const updateEcg = (_id, changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ECG_UPDATE_REQUEST
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const response = await axios.patch(
            `/api/v1/ecg/${_id}/`,
            changes,
            config
        );

        dispatch({
            type: ECG_UPDATE_SUCCESS,
            payload: response.data, // Assuming the updated ecg data is returned
        });

        dispatch({
            type: ECG_DETAILS_SUCCESS,
            payload: response.data, // Assuming the updated ecg data is returned
        });
    } catch (error) {
        let errorPayload = [];

        if (error.response && error.response.status === 400 && error.response.data.errors) {
            const validationErrors = error.response.data.errors;
            // Concatenate detailed validation errors to the error payload array
            errorPayload = Object.values(validationErrors).flat();
        }

        // Include generic error message or additional error data in the payload array
        if (error.response && error.response.data.detail) {
            errorPayload.push(error.response.data.detail);
        } else {
            errorPayload.push(error.message);
        }

        dispatch({
            type: ECG_UPDATE_FAIL,
            payload: errorPayload,
        });
    }
};
