import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Table, ActionLink, Label, Tag, Input, Fieldset, Radios, BackLink, Card, Details, Button, DateInput, InsetText, Textarea, ErrorSummary } from 'nhsuk-react-components';
import { listEcgs, createEcg, updateEcg, deleteEcg, getEcgDetails } from '../../actions/ecgActions';
// constants 
import { ECG_CREATE_RESET, ECG_UPDATE_RESET } from '../../constants/ecgConstants';
import Pagination from '../../components/pagination/Pagination.component';
import AppSpinner from '../../components/spinner/AppSpinner.component';

import Callout from '../../components/callout/Callout.component';

// debouncer 
import { debounce } from 'lodash';

export default function EcgCRUDScreen({ history }) {

    // use react hook Dispatch to dispatch actions
    const dispatch = useDispatch();

    // reset the state of the ecgCreate and ecgUpdate
    useEffect(() => {
        dispatch({ type: ECG_CREATE_RESET });
        dispatch({ type: ECG_UPDATE_RESET });
    }, [dispatch]);

    // set initial filter to empty string, set default value 
    // {{ _.docker_api_url }}/api/v1/ecg/all?page=1&limit=2&filter=Email&query=gmail
    const [ecgListFilter, setecgListFilter] = useState({ query: '', page: 1, filter: 'Name' });

    // get ecg list from redux store
    const ecgList = useSelector((state) => state.ecgList);
    const { loading, error, ecgs, currentPage, totalPages } = ecgList;

    // get ecg create from redux store
    const ecgCreate = useSelector((state) => state.ecgCreate);
    const { success: successCreate, error: ecgCreateErrors } = ecgCreate;

    // get ecg update from redux store
    const ecgUpdate = useSelector((state) => state.ecgUpdate);
    const { success: successUpdate, loading: ecgUpdateProcessing } = ecgUpdate;

    // get ecg delete from redux store
    const ecgDelete = useSelector((state) => state.ecgDelete);
    const { success: successDelete } = ecgDelete;

    // get ecg details from redux store
    const ecgDetails = useSelector((state) => state.ecgDetails);
    const { ecg: ecgDet, loading: ecgDetailsLoading } = ecgDetails;

    // newEcg state
    const [newEcg, setNewEcg] = useState({});
    const [updatedEcg, setUpdatedEcg] = useState({});
    const [editMode, setEditMode] = useState(false);


    // listen to filter changes
    useEffect(() => {
        dispatch(listEcgs(ecgListFilter));
    }, [dispatch, ecgListFilter]);

    // handle page change
    const handlePageChange = (newPage) => {
        setecgListFilter({ ...ecgListFilter, page: newPage });
    };

    // handle search text field change
    const handleSearchChange = (e) => {
        setecgListFilter({ ...ecgListFilter, query: e.target.value });
    };

    // handle filter value change
    const handleFilterChange = (e) => {
        const selectedFilter = e.target.value;
        setecgListFilter({ ...ecgListFilter, filter: selectedFilter });
    };

    // get all ecgs
    useEffect(() => {
        dispatch(listEcgs({}));
    }, [dispatch]);

    // listen to ecgDet 
    useEffect(() => {
        if (ecgDet) {
            // use spread operator to copy the ecgDet object
            setUpdatedEcg({ ...ecgDet });
        }
    }, [ecgDet]);

    // listen to editMode
    useEffect(() => {
        if (editMode === false) {
            // empty the newEcg state
            setNewEcg({
                name: '',
                detail: '',
            });
        }
    }, [editMode]);

    // handle delete
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this ecg?')) {
            dispatch(deleteEcg(id)).then(() => {
                dispatch(listEcgs({}));
            }
            );
        }
    };

    // handle edit
    const handleEditEcg = (id) => {
        dispatch(getEcgDetails(id));
        setEditMode(true);
    };

    // handle create
    const handleCreateEcg = () => {
        dispatch(createEcg(newEcg)).then(() => {
            setUpdatedEcg(newEcg);
            setEditMode(true);
            dispatch(listEcgs({}));
        }
        );
    };

    // handle update
    const handleUpdateEcg = () => {
        dispatch(updateEcg(updatedEcg.id, updatedEcg)).then(() => {

            setUpdatedEcg(updatedEcg);
            setEditMode(true);
            dispatch(getEcgDetails(updatedEcg.id));
        }
        );
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <div className="welcome-box">
                                <div className="welcome-title d-flex justify-content-between align-items-center">
                                    <h2>Ecg Management</h2>
                                </div>
                                <BackLink
                                    asElement="a"
                                    href="#"
                                    onClick={() => history.goBack()}
                                >
                                    Return back
                                </BackLink>
                                <InsetText>
                                    <p>
                                        This section allows you to manage and configure the ecgs. You can create, update and delete ecgs in one place!
                                    </p>
                                </InsetText>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">

                            {ecgCreateErrors ?

                                <ErrorSummary
                                    aria-labelledby="error-summary-title"
                                    role="alert"
                                    tabIndex="-1"
                                >
                                    <ErrorSummary.Title id="error-summary-title">
                                        There is a problem
                                    </ErrorSummary.Title>
                                    <ErrorSummary.Body>
                                        <p>Check the following:</p>
                                        {ecgCreateErrors ?
                                            <ul className="nhsuk-list nhsuk-list--bullet">
                                                {ecgCreateErrors.map((error) => (
                                                    <li key={error}>{error}</li>
                                                ))}
                                            </ul>
                                            : null

                                        }

                                    </ErrorSummary.Body>
                                </ErrorSummary>
                                : null}
                        </Col>
                    </Row>


                    <div className="nhsuk-grid-column-one-half">
                        <Card>
                            <Card.Content>
                                <Card.Heading className="nhsuk-heading-m">
                                    <Input
                                        id="search-ecgs"
                                        label="Search ecgs"
                                        name="search-ecgs"
                                        type="search"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSearchChange(e);
                                            }
                                        }
                                        }
                                    />
                                </Card.Heading>
                                <Card.Description>
                                    <Fieldset>
                                        {ecgs && ecgs.length > 0 ? (
                                            ecgs.map((ecg) => (
                                                <Details key={ecg._id}>
                                                    <Details.Summary>
                                                        {ecg.name}
                                                    </Details.Summary>
                                                    <Details.Text>
                                                        <p>
                                                            <strong>Description:</strong> {ecg.detail}
                                                        </p>
                                                        <div className="d-flex justify-content-between">
                                                            <Button
                                                                disabled={ecgDetailsLoading}
                                                                onClick={() =>
                                                                    handleEditEcg(ecg.id)
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                            {" "}
                                                            <Button
                                                                secondary

                                                                onClick={() => handleDelete(ecg.id)}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </Details.Text>
                                                </Details>
                                            ))
                                        ) : (
                                            null
                                        )}

                                    </Fieldset>
                                    {loading ? (
                                        <AppSpinner />
                                    ) : error ? (
                                        <p>{error}</p>
                                    ) : (
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            handlePageChange={handlePageChange}
                                        />

                                    )}

                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </div>
                    <div className="nhsuk-grid-column-one-half">
                        {editMode ? (
                            <Card id='edit-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Edit Ecg
                                        <InsetText>
                                            <p>
                                                This section allows you to edit an ecg. If you want to create a new ecg, please click the "Create Ecg" button below.
                                            </p>
                                            <Button
                                                onClick={() => {
                                                    setEditMode(false);
                                                }}
                                            >
                                                + Create Ecg
                                            </Button>

                                        </InsetText>

                                    </Card.Heading>
                                    <Card.Description>
                                        <Input
                                            id="name"
                                            label="Name"
                                            name="name"
                                            type="text"
                                            onChange={(e) => setUpdatedEcg({ ...updatedEcg, name: e.target.value })}
                                            value={updatedEcg.name ? updatedEcg.name : ''}
                                            required
                                        />
                                        <Textarea
                                            id="detail"
                                            label="Description"
                                            name="detail"
                                            onChange={(e) => setUpdatedEcg({ ...updatedEcg, detail: e.target.value })}
                                            value={updatedEcg.detail ? updatedEcg.detail : ''}
                                        />


                                        {(successCreate || successUpdate) ?
                                            <Callout
                                                label="Success"
                                                header="Success!"
                                                autoDismiss={true}
                                                dismissTime={5000}
                                                time={new Date().toLocaleTimeString()}
                                                title="Success!"
                                                message={successCreate ? "The ecg was created successfully." : "The ecg was updated successfully."}
                                                mode="success"
                                            />
                                            : null
                                        }

                                    </Card.Description>
                                    <Button
                                        disabled={ecgUpdateProcessing}
                                        onClick={handleUpdateEcg}
                                    >
                                        Save changes
                                    </Button>

                                </Card.Content>
                            </Card>
                        ) : (
                            <Card id='create-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Create a new Ecg
                                    </Card.Heading>
                                    <Card.Description>
                                        <Input
                                            id="name-create"
                                            label="Name"
                                            name="name-create"
                                            type="text"
                                            onChange={(e) => setNewEcg({ ...newEcg, name: e.target.value })}
                                            value={newEcg?.name}
                                            required
                                        />
                                        <Textarea
                                            id="detail-create"
                                            label="Description"
                                            name="detail-create"
                                            onChange={(e) => setNewEcg({ ...newEcg, detail: e.target.value })}
                                            value={newEcg?.detail}
                                        />

                                    </Card.Description>

                                    <Button
                                        disabled={ecgUpdateProcessing}
                                        onClick={handleCreateEcg}
                                    >
                                        Create Ecg
                                    </Button>

                                </Card.Content>
                            </Card>
                        )}

                    </div>

                </Container>
            </div>
        </React.Fragment>

    );
}






