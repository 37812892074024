import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEMPLATE_DETAILS_REQUEST } from '../../constants/templateConstants';
import { getTemplateDetailsByName } from '../../actions/templateActions';
import {Button} from "nhsuk-react-components";

// Import html2pdf library
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';

const PhysicalCard = ({ nhsNumber, birthdate, etiologies, symptoms, ecgIndications, mriConditional, generator, leads }) => {
  // reset states
  const dispatch = useDispatch();

  // get the physicalCard template from the store
  const templateDetailsByName = useSelector((state) => state.templateDetailsByName);
  const { loading, error, template } = templateDetailsByName;

  const [card, setCard] = useState({});
  const [zoomLevel, setZoomLevel] = useState(100); // initial zoom level

  // dispatch the action to get the template
  useEffect(() => {
    dispatch(getTemplateDetailsByName('physicalCard'));
  }, [dispatch]);

  // listen to template
  useEffect(() => {
    if (template && template.htmlContent) {
      // replace props with template values
      var cardContent = template.htmlContent;
      cardContent = cardContent.replace('{nhsNumber}', nhsNumber);
      cardContent = cardContent.replace('{birthdate}', birthdate);
      cardContent = cardContent.replace('{etiologies}', etiologies || '-');
      cardContent = cardContent.replace('{symptoms}', symptoms || '-');
      cardContent = cardContent.replace('{ecgIndications}', ecgIndications || '-');
      cardContent = cardContent.replace('{mriConditional}', mriConditional);
      cardContent = cardContent.replace('{generator}', generator || '-');
      cardContent = cardContent.replace('{leads}', leads || '-');
      setCard(cardContent);
    }
  }, [template]);

  const generatePDF = () => {
    // Select the element containing the card content
    const element = document.getElementById('card-content');

    // Configuration for the PDF
    const options = {
      filename: 'physical_card.pdf',
      jsPDF: { unit: 'mm', format: [172, 108.5 ], orientation: 'l' },
      Image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      
    };

    // Generate PDF
    html2pdf().from(element).set(options).save();
  };

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 5);
  };

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 5);
  };

  const zoomStyle = {
    transform: `scale(${zoomLevel / 100})`,
    transformOrigin: 'top left',
  };

  return (
    <React.Fragment>
      <div>
        <Button onClick={generatePDF}>Download PDF</Button>
      </div>
      {(loading || !card) ? (
        <h2>Loading...</h2>
      ) : error ? (
        <h3>{error}</h3>
      ) : (
        <div id="card-content" style={zoomStyle} className="card-content" dangerouslySetInnerHTML={{ __html: card }} />
      )}
    </React.Fragment>
  );
};

export default PhysicalCard;
